import React, { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";

const AddFolder = ({ Show, Hide, Size, Title, Submit, mode, setMode }) => {
  const [folderName, setFolderName] = useState("");
  return (
    <div>
      <Modal
        show={Show}
        onHide={Hide}
        size={Size}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{Title}</Modal.Title>
          <div onClick={() => Hide()} className="close_modal" title="Close">
            X
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form.Group as={Col} md={12}>
              <Form.Label className="mb-1 fw-bold">
                Folder Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Folder Name"
                autoFocus
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            disabled={!folderName}
            className="p-1 px-3 border rounded-2 text-white bg-color fs-13"
          >
            {Submit}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddFolder;

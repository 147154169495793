import React, { useState } from "react";
import Moment from "react-moment";
import { Table } from "react-bootstrap";
import Pagination from "../../../../Hooks/Pagination";

const TemplatesTableDesign = () => {

    const templatesDetailsData = [
        {
            id: "1",
            templatesName: "Satish Kumar Gautam templates",
            deliveryMode: "email",
            createdAt: "2024-07-31 13:00:00",
            status: "completed",
        },
        {
            id: "2",
            templatesName: "Satish Kumar Gautam templates",
            deliveryMode: "email",
            createdAt: "2024-07-31 13:00:00",
            status: "signed",
        },
        {
            id: "3",
            templatesName: "Satish Kumar Gautam templates",
            deliveryMode: "email",
            createdAt: "2024-07-31 13:00:00",
            status: "incompleted",
        },
        {
            id: "4",
            templatesName: "Satish Kumar Gautam templates",
            deliveryMode: "email",
            createdAt: "2024-07-31 13:00:00",
            status: "completed",
        },
        {
            id: "5",
            templatesName: "Satish Kumar Gautam templates",
            deliveryMode: "email",
            createdAt: "2024-07-31 13:00:00",
            status: "signed",
        },
        {
            id: "6",
            templatesName: "Satish Kumar Gautam templates",
            deliveryMode: "email",
            createdAt: "2024-07-31 13:00:00",
            status: "incompleted",
        },
        {
            id: "7",
            templatesName: "Satish Kumar Gautam templates",
            deliveryMode: "email",
            createdAt: "2024-07-31 13:00:00",
            status: "completed",
        },
        {
            id: "8",
            templatesName: "Satish Kumar Gautam templates",
            deliveryMode: "email",
            createdAt: "2024-07-31 13:00:00",
            status: "signed",
        },
        {
            id: "9",
            templatesName: "Satish Kumar Gautam templates",
            deliveryMode: "email",
            createdAt: "2024-07-31 13:00:00",
            status: "incompleted",
        },
        {
            id: "10",
            templatesName: "Satish Kumar Gautam templates",
            deliveryMode: "email",
            createdAt: "2024-07-31 13:00:00",
            status: "completed",
        },
        {
            id: "11",
            templatesName: "Satish Kumar Gautam templates",
            deliveryMode: "email",
            createdAt: "2024-07-31 13:00:00",
            status: "signed",
        },
        {
            id: "12",
            templatesName: "Satish Kumar Gautam templates",
            deliveryMode: "email",
            createdAt: "2024-07-31 13:00:00",
            status: "completed",
        },
    ];

    const [pageNumber, setPageNumber] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const result = templatesDetailsData?.slice(startIndex, endIndex);
    const totalPage = Math.ceil(templatesDetailsData?.length / itemsPerPage);

    return (
        <>
            <Table className="text-nowrap mb-1" hover bordered>
                <thead className="position-sticky z-1" style={{ top: -1 }}>
                    <tr>
                        <th >S.No</th>
                        <th >Templates Name</th>
                        <th style={{ width: 150 }}>Delivery Mode</th>
                        <th >Created At</th>
                        <th >
                            <div>Status</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {result?.map((item, index) => (
                        <tr className="position-relative CP">
                            <td style={{ width: 50 }}>{index + 1 + startIndex}</td>
                            <td>{item.templatesName}</td>
                            <td>{item.deliveryMode}</td>
                            <td>
                                <Moment format="DD MMM YYYY | HH:mm">
                                    {item.createdAt}
                                </Moment>
                            </td>
                            <td className="text-capitalize">{item.status}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className="position-sticky bottom-0">
                <Pagination
                    totalPage={totalPage}
                    pageNumber={pageNumber}
                    itemsPerPage={itemsPerPage}
                    totalItems={templatesDetailsData?.length}
                    setPageNumber={setPageNumber}
                    setItemsPerPage={setItemsPerPage}
                />
            </div>
        </>
    );
};

export default TemplatesTableDesign;


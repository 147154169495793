import React, { useState } from "react";
import {
  Col,
  Row,
  Form,
  Spinner,
  Container,
} from "react-bootstrap";

const StudyDetails = () => {
  const [loader, setLoader] = useState(false);
  
  return (
    <>
      <Container
        fluid
        className="overflow-auto"
        style={{ height: "calc(100vh - 115px)" }}
      >
        <>
        <Row>
        <Form.Group as={Col} md="12" lg="12" sm="12" className="px-1">
          <div className="shadow-md border my-2 mx-1 rounded-1 overflow-hidden">
            <Form.Group className="border-bottom pb-2 p-2 hstack justify-content-between bg-light">
              <Form.Label className="m-0 text-nowrap fw-bold fs-13">
                Additional Details
              </Form.Label>
            </Form.Group>
            <div className="d-flex gap-3 p-2" style={{ flexFlow: "wrap" }}>
              {loader ? (
                <div className="hstack justify-content-center h-100 w-100 py-5">
                  <Spinner />
                </div>
              ) : (
                <div className="hstack justify-content-center h-100 w-100 py-5 fs-13">
                  Additional Details not found!
                </div>
              )}
            </div>
          </div>
        </Form.Group>
      </Row>
        </>
      </Container>
    </>
  );
};

export default StudyDetails;

import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { FaRegEye } from "react-icons/fa6";

const RecentActivity = ({ handleAddNewTab }) => {
  const [loader, setLoader] = useState(false);

  const activities = [
    {
      date: "Sep 25",
      action: "Update",
      text: "Responded to need",
      linkText: "“Volunteer opportunity”",
      linkHref: "#",
    },
    {
      date: "Sep 24",
      text: "Added an interest “Volunteer Activities”",
      action: "Add",
    },
    {
      date: "Sep 23",
      text: "Joined the group",
      linkText: "“Boardsmanship Forum”",
      action: "Join",
      linkHref: "#",
    },
    {
      date: "Sep 21",
      text: "Responded to need",
      linkText: "“In-Kind Opportunity”",
      action: "Reply",
      linkHref: "#",
    },
    {
      date: "Sep 18",
      text: "Created need",
      linkText: "“Volunteer Opportunity”",
      action: "Add",
      linkHref: "#",
    },
    {
      date: "Sep 17",
      text: "Attending the event",
      linkText: "“Some New Event”",
      action: "Add",
      linkHref: "#",
    },
  ];

  return (
    <>
      {loader ? (
        <div className="hstack justify-content-center h-50">
          <Spinner />
        </div>
      ) : activities?.length === 0 ? (
        <div className="hstack justify-content-center h-50">
          Data not found!
        </div>
      ) : (
        <>
          <div
            className="p-3 pb-0 overflow-auto"
            style={{ maxHeight: "calc(100vh - 425px)" }}
          >
            <ol className="p-0 m-0" style={{ listStyle: "none" }}>
              {activities.map((activity, index) => (
                <li
                  key={index}
                  className="feed-item position-relative border-start pb-2 ps-4"
                >
                  <div
                    className="fs-13 text-muted d-block"
                    dateTime={activity.date.replace(" ", "-").toLowerCase()}
                  >
                    {activity?.date}{" "}
                    <span className="fs-10">
                      {"(" + activity?.action + ")"}
                    </span>
                  </div>
                  <Form.Label className="position-relative">
                    {activity.text}{" "}
                    {activity.linkHref ? (
                      <a href={activity.linkHref}>{activity.linkText}</a>
                    ) : (
                      activity.linkText
                    )}
                  </Form.Label>
                </li>
              ))}
            </ol>
          </div>
          <div className="text-end">
            <button
              onClick={() =>
                handleAddNewTab(
                  "Recent Activity",
                  "fa-solid fa-history",
                  "recentActivityId"
                )
              }
              className={`p-1 px-3 border rounded-1 text-white bg-color fs-13`}
            >
              <FaRegEye style={{marginBottom: 2}}/> View All
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default RecentActivity;

import React, { useState } from "react";
import { Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import Pagination from "../../../Hooks/Pagination";
import Moment from "react-moment";
import { FaPlusSquare } from "react-icons/fa";

const StudyProtocol = () => {
  const [parameterName, setParameterName] = useState("");
  const [parameterLabel, setParameterLabel] = useState("");
  const [parameterValue, setParameterValue] = useState("");
  const [loader, setLoader] = useState(false);

  const studyProtocol = [
    {
      _id: "6693961cbb1a3c77c96ca3a1",
      study_id: "projdemo001study0029316625",
      parameterName: "PROTONIM",
      parameterLabel: "Protocol Number",
      type: "pre",
      __v: 0,
      createdAt: "2024-07-14T09:10:52.613Z",
      updatedAt: "2024-07-22T18:59:50.397Z",
      parameterValue: "PROT001",
    },
    {
      _id: "6693961cbb1a3c77c96ca3a2",
      study_id: "projdemo001study0029316625",
      parameterName: "PLANSUB",
      parameterLabel: "Planned Number of Subjects",
      type: "pre",
      __v: 0,
      createdAt: "2024-07-14T09:10:52.613Z",
      updatedAt: "2024-07-14T09:10:52.613Z",
    },
    {
      _id: "6693961cbb1a3c77c96ca3a3",
      study_id: "projdemo001study0029316625",
      parameterName: "TITLE",
      parameterLabel: "Trial Title",
      type: "pre",
      __v: 0,
      createdAt: "2024-07-14T09:10:52.613Z",
      updatedAt: "2024-07-14T09:10:52.613Z",
    },
    {
      _id: "6693961cbb1a3c77c96ca3a4",
      study_id: "projdemo001study0029316625",
      parameterName: "STUDYVER",
      parameterLabel: "Active Study Version",
      type: "pre",
      __v: 0,
      createdAt: "2024-07-14T09:10:52.613Z",
      updatedAt: "2024-07-14T09:10:52.613Z",
    },
    {
      _id: "6693961cbb1a3c77c96ca3a5",
      study_id: "projdemo001study0029316625",
      parameterName: "RANDOM",
      parameterLabel: "Trial is Randomized",
      type: "pre",
      __v: 0,
      createdAt: "2024-07-14T09:10:52.613Z",
      updatedAt: "2024-07-14T09:10:52.613Z",
    },
    {
      _id: "6693961cbb1a3c77c96ca3a6",
      study_id: "projdemo001study0029316625",
      parameterName: "TBLIND",
      parameterLabel: "ial Blinding Schema",
      type: "pre",
      __v: 0,
      createdAt: "2024-07-14T09:10:52.613Z",
      updatedAt: "2024-07-14T09:10:52.613Z",
    },
    {
      _id: "6693961cbb1a3c77c96ca3a7",
      study_id: "projdemo001study0029316625",
      parameterName: "NARMS",
      parameterLabel: "Planned Number of Arms",
      type: "pre",
      __v: 0,
      createdAt: "2024-07-14T09:10:52.613Z",
      updatedAt: "2024-07-14T09:10:52.613Z",
    },
    {
      _id: "6693961cbb1a3c77c96ca3a8",
      study_id: "projdemo001study0029316625",
      parameterName: "INTMODEL",
      parameterLabel: "Intervention Model",
      type: "pre",
      __v: 0,
      createdAt: "2024-07-14T09:10:52.613Z",
      updatedAt: "2024-07-14T09:10:52.613Z",
    },
    {
      _id: "6693961cbb1a3c77c96ca3a9",
      study_id: "projdemo001study0029316625",
      parameterName: "SSTDTC",
      parameterLabel: "Study Start Date",
      type: "pre",
      __v: 0,
      createdAt: "2024-07-14T09:10:52.613Z",
      updatedAt: "2024-07-14T09:10:52.613Z",
    },
    {
      _id: "6693961cbb1a3c77c96ca3aa",
      study_id: "projdemo001study0029316625",
      parameterName: "SENDTC",
      parameterLabel: "Study End Date",
      type: "pre",
      __v: 0,
      createdAt: "2024-07-14T09:10:52.614Z",
      updatedAt: "2024-07-14T09:10:52.614Z",
    },
    {
      _id: "6693961cbb1a3c77c96ca3ab",
      study_id: "projdemo001study0029316625",
      parameterName: "LENGTH",
      parameterLabel: "Trial Length",
      type: "pre",
      __v: 0,
      createdAt: "2024-07-14T09:10:52.614Z",
      updatedAt: "2024-07-14T09:10:52.614Z",
    },
    {
      _id: "6693961cbb1a3c77c96ca3ac",
      study_id: "projdemo001study0029316625",
      parameterName: "STYPE",
      parameterLabel: "Study Type",
      type: "pre",
      __v: 0,
      createdAt: "2024-07-14T09:10:52.614Z",
      updatedAt: "2024-07-14T09:10:52.614Z",
    },
    {
      _id: "6693961cbb1a3c77c96ca3ad",
      study_id: "projdemo001study0029316625",
      parameterName: "THERAREA",
      parameterLabel: "Therapeutic Area",
      type: "pre",
      __v: 0,
      createdAt: "2024-07-14T09:10:52.614Z",
      updatedAt: "2024-07-14T09:10:52.614Z",
    },
    {
      _id: "6693961cbb1a3c77c96ca3ae",
      study_id: "projdemo001study0029316625",
      parameterName: "TPHASE",
      parameterLabel: "Trial Phase Classification",
      type: "pre",
      __v: 0,
      createdAt: "2024-07-14T09:10:52.614Z",
      updatedAt: "2024-07-14T09:10:52.614Z",
    },
    {
      _id: "6693961cbb1a3c77c96ca3af",
      study_id: "projdemo001study0029316625",
      parameterName: "TTYPE",
      parameterLabel: "Trial Type",
      type: "pre",
      __v: 0,
      createdAt: "2024-07-14T09:10:52.614Z",
      updatedAt: "2024-07-14T09:10:52.614Z",
    },
    {
      _id: "6693961cbb1a3c77c96ca3b0",
      study_id: "projdemo001study0029316625",
      parameterName: "OBJPRIM",
      parameterLabel: "Trial Primary Objective",
      type: "pre",
      __v: 0,
      createdAt: "2024-07-14T09:10:52.614Z",
      updatedAt: "2024-07-14T09:10:52.614Z",
    },
    {
      _id: "6693961cbb1a3c77c96ca3b1",
      study_id: "projdemo001study0029316625",
      parameterName: "OBJSEC",
      parameterLabel: "Trial Secondary Objective",
      type: "pre",
      __v: 0,
      createdAt: "2024-07-14T09:10:52.614Z",
      updatedAt: "2024-07-14T09:10:52.614Z",
    },
    {
      _id: "6693961cbb1a3c77c96ca3b2",
      study_id: "projdemo001study0029316625",
      parameterName: "SPONSOR",
      parameterLabel: "Clinical Study Sponsor",
      type: "pre",
      __v: 0,
      createdAt: "2024-07-14T09:10:52.614Z",
      updatedAt: "2024-07-14T09:10:52.614Z",
    },
  ];

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = studyProtocol?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(studyProtocol?.length / itemsPerPage);
  return (
    <>
      <Container fluid>
        <Row>
          <Form.Group
            as={Col}
            md="3"
            lg="3"
            sm="3"
            className="px-1 border-end overflow-auto"
            style={{ height: "calc(100vh - 115px)" }}
          >
            <div className="my-2 mx-1 rounded-1 overflow-hidden">
              <Form.Group className="mb-2">
                <Form.Label className="mb-1">
                  Parameter Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Parameter Name"
                  value={parameterName}
                  onChange={(e) => setParameterName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label className="mb-1">
                  Parameter Label <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Parameter Label"
                  value={parameterLabel}
                  onChange={(e) => setParameterLabel(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1">
                  Parameter Value <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Parameter Value"
                  value={parameterValue}
                  onChange={(e) => setParameterValue(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="text-end">
                <button className="p-1 px-3 border rounded-1 text-white bg-color fs-13">
                  <FaPlusSquare style={{ marginBottom: 2 }} /> Add Protocol
                </button>
              </Form.Group>
            </div>
          </Form.Group>
          <Form.Group as={Col} md="9" lg="9" sm="9" className="px-1">
            <div className="my-2 mx-1 rounded-1 overflow-hidden">
              {loader ? (
                <div className="hstack justify-content-center h-75">
                  <Spinner />
                </div>
              ) : studyProtocol?.length === 0 ? (
                <div className="hstack justify-content-center h-75">
                  Data not found!
                </div>
              ) : (
                <>
                  <div
                    className="overflow-auto"
                    style={{ maxHeight: "calc(100vh - 175px)" }}
                  >
                    <Table className="text-nowrap mb-1" hover bordered>
                      <thead className="position-sticky" style={{ top: -1 }}>
                        <tr>
                          <th style={{ width: 40 }}>Sr</th>
                          <th>Parameter Name</th>
                          <th>Parameter Label</th>
                          <th>Parameter Value</th>
                          <th>Created Date | Time</th>
                          <th>Update Date | Time</th>
                          <th style={{ width: 60 }}>
                            <div className="text-center">Remove</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {result.map((item, index) => (
                          <tr key={index} className="position-relative CP">
                            <td>
                              {(pageNumber - 1) * itemsPerPage + index + 1}
                            </td>
                            <td>{item.parameterName}</td>
                            <td>{item.parameterLabel}</td>
                            <td>{item.parameterValue}</td>
                            <td>
                              <Moment format="DD MMM YYYY | HH:mm:ss">
                                {item.createdAt}
                              </Moment>
                            </td>
                            <td>
                              <Moment format="DD MMM YYYY | HH:mm:ss">
                                {item.updatedAt}
                              </Moment>
                            </td>
                            <td>
                              <div className="text-center">
                                {item.type === "post" ? (
                                  <i
                                    title="Delete Protocol"
                                    className={`fa-solid fa-trash-can CP red`}
                                  ></i>
                                ) : (
                                  <i
                                    title="No Delete Pre-Define Prorocol"
                                    className={`fa-solid fa-trash-can gray`}
                                  ></i>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Pagination
                    totalPage={totalPage}
                    pageNumber={pageNumber}
                    itemsPerPage={itemsPerPage}
                    totalItems={studyProtocol?.length}
                    setPageNumber={setPageNumber}
                    setItemsPerPage={setItemsPerPage}
                  />
                </>
              )}
            </div>
          </Form.Group>
        </Row>
      </Container>
    </>
  );
};

export default StudyProtocol;

import React, { useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { TiPin } from 'react-icons/ti';
import { LuDelete } from 'react-icons/lu';
import { useDrag, useDrop } from 'react-dnd';
import { HiMiniXMark } from 'react-icons/hi2';
import { VscCloseAll } from 'react-icons/vsc';
import RightClick from '../../Hooks/RightClick';
import { MdOutlineDelete } from 'react-icons/md';
import { ContextMenuTrigger } from 'react-contextmenu';
import { calculateDuration } from '../../Utils/UniversalFunction';
import { IoIosArrowDropleft, IoIosArrowDropright, IoMdCloseCircleOutline } from 'react-icons/io';

const ItemType = 'TAB';

const DraggableTab = ({
    item,
    index,
    handleMoveTab,
    handleActiveTab,
    handleDeleteTab,
    handleMoveTabLeft,
    handleMoveTabRight,
    handleDeleteAllTab,
    handleCloseOtherTab,
    handleCloseLeftTabs,
    handleCloseRightTabs,
}) => {
    const ref = useRef(null);
    const [isDragging, setIsDragging] = useState(false);

    const [, drop] = useDrop({
        accept: ItemType,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                handleMoveTab(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
        collect: (monitor) => {
            setIsDragging(monitor.isOver());
        },
    });

    const [{ isDraggingItem }, drag] = useDrag({
        type: ItemType,
        item: { index },
        collect: (monitor) => ({
            isDraggingItem: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <div
            ref={ref}
            className={`tablist ${item.status}`}
            style={{
                background: "var(--resizeBg)",
                borderRadius: "3px 3px 0 0",
                opacity: isDraggingItem ? 0.5 : 1,
                transition: 'transform 0.2s ease',
            }}
        >
            <ContextMenuTrigger
                id={item?.tabId + "uniqueId"}
                collect={() => ({ id: item?.tabId })}
                disable={isDragging}
            >
                <div
                    key={index}
                    className={`hstack ps-2 pe-1 tablist ${item.status}`}
                    style={{ padding: 2, borderRadius: item.status === "active" && "3px 3px 0 0" }}
                >
                    <Form.Label
                        onClick={() => handleActiveTab(item.tabId)}
                        title={`Tab Name: ${item.tabName
                            }\nOpened Time: ${calculateDuration(item?.openDT)}`}
                        className={`m-0 hstack cursor-pointer text-truncate me-2 ${item.status === "active" ? "text-white" : "text-muted"
                            }`}
                        style={{ minWidth: 100 }}
                    >
                        <i className={`${item.icon} me-2`}></i>
                        <span style={{ marginBottom: -2 }}>{item.tabName}</span>
                    </Form.Label>
                    <HiMiniXMark
                        onClick={() => handleDeleteTab(item.tabId)}
                        className={
                            item.status === "active" ? "text-white" : "text-muted"
                        }
                        title="Close Tab"
                    />
                </div>
            </ContextMenuTrigger>
            <RightClick
                ContextId={item?.tabId + "uniqueId"}
                Menu1
                MenuName1={"Pin"}
                icons1={<TiPin className="text-white" />}
                className1="text-white"
                Menu2
                MenuName2={"Move Left"}
                icons2={<IoIosArrowDropleft className="text-white" />}
                handleClick2={() => handleMoveTabLeft(index)}
                className2="text-white"
                Menu3
                MenuName3={"Move Right"}
                icons3={<IoIosArrowDropright className="text-white" />}
                handleClick3={() => handleMoveTabRight(index)}
                className3="text-white"
                Menu4
                MenuName4={"Close Tab"}
                icons4={<IoMdCloseCircleOutline className="text-white" />}
                handleClick4={() => handleDeleteTab(item.tabId)}
                className4="text-white"
                Menu5
                MenuName5={"Close Other Tab"}
                icons5={<VscCloseAll className="text-white" />}
                handleClick5={() => handleCloseOtherTab(item)}
                className5="text-white"
                Menu6
                MenuName6={"Close Right Tab"}
                icons6={
                    <LuDelete
                        className="text-white"
                        style={{ transform: "rotate(180deg)" }}
                    />
                }
                handleClick6={() => handleCloseRightTabs(index)}
                className6="text-white"
                Menu7
                MenuName7={"Close Left Tab"}
                icons7={<LuDelete className="text-white" />}
                handleClick7={() => handleCloseLeftTabs(index)}
                className7="text-white"
                Menu8
                MenuName8={"Close All Tab"}
                icons8={<MdOutlineDelete className="text-white" />}
                handleClick8={() => handleDeleteAllTab()}
                className8="text-white"
            />
        </div>
    );
};

export default DraggableTab;

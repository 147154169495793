import React, { useState } from "react";
import SplitPane from "react-split-pane";
import { Spinner } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import DocumentHistory from "./DocumentHistory";
import UseToggle from "../../../Hooks/UseToggle";
import RecipientDetails from "./RecipientDetails";
import CodeListTableData from "./CodeListTableData";
import { BiCollapse, BiExpand } from "react-icons/bi";
import { CiFilter, CiViewTable } from "react-icons/ci";
import CodeListTableProperties from "./CodeListTableProperties";
import CodeListPropertiesTableData from "./CodeListPropertiesTab/CodeListPropertiesTableData";

const CodeList = ({handleAddNewTab}) => {
  const { toggle, setToggle } = UseToggle();
  const [loader, setLoader] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isHalfScreen, setIsHalfScreen] = useState(false);
  const [activeTabs, setActiveTabs] = useState("properties");

  const toggleHalfScreen = () => {
    setIsHalfScreen(!isHalfScreen);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const CodeList = Array.from({ length: 15 }, (_, index) => ({
    _id: index + 1,
    codeListId: ["List A", "List B", "List C"][Math.floor(Math.random() * 3)],
    codeListName: `Code List ${Math.floor(Math.random() * 15) + 1}`,
    nciClodeListCode: `NCI${Math.floor(10000 + Math.random() * 90000)}`,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  }));

  const tableData = Array.from({ length: 15 }, (_, index) => ({
    _id: index + 1,
    submissionValue: ["Mild", "Moderate", "Severe"][
      Math.floor(Math.random() * 3)
    ],
    synonyms: `${Math.floor(Math.random() * 15) + 1}; Grade ${Math.floor(Math.random() * 15) + 1
      }`,
    nciCode: `C${Math.floor(10000 + Math.random() * 90000)}`,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  }));

  const HeaderTools = [
    { title: "Add New Document", icon: <AiOutlinePlus />, onClick: () => handleAddNewTab("Add New Document", "fa-solid fa-file", "addNewDocumentId") },
    {
      title: "View Table Properties",
      icon: <CiViewTable />,
      onClick: setToggle,
    },
    { title: "Filter", icon: <CiFilter /> },
  ];

  return (
    <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
      <div
        className="hstack justify-content-between w-100 border-bottom"
        style={{ padding: "4.6px 10px" }}
      >
        <div className="hstack gap-2">
          {HeaderTools?.map((button, index) => (
            <React.Fragment key={index}>
              <button
                title={button.title}
                className="tabButtons border rounded-1 fs-13"
                style={{ padding: "1px 5px" }}
                onClick={button.onClick}
              >
                {button.icon}
              </button>
              {index < 3 && <div className="vr"></div>}
            </React.Fragment>
          ))}
        </div>
        <button
          className="tabButtons border rounded-1 fs-13"
          title={!isFullScreen ? "Full Mode" : "Original Mode"}
          onClick={toggleFullScreen}
        >
          {isFullScreen ? <BiCollapse /> : <BiExpand />}
        </button>
      </div>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{
            height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 148px)",
          }}
          defaultSize="40%"
        >
          <SplitPane split="vertical" minSize={20} defaultSize="100%">
            <div className="pt-0">
              <CodeListTableData CodeList={CodeList} />
            </div>
          </SplitPane>
          <div className="position-relative" style={{ height: "100%" }}>
            <div
              className="hstack justify-content-between border-bottom px-1 pb-0 bg-light"
              style={{ padding: 5 }}
            >
              <div className="hstack gap-2">
                {["properties", "history", "recipientDetails"].map((tab) => (
                  <button
                    key={tab}
                    className={`tabButtons border-0 fs-13 p-1 ${activeTabs === tab ? "active" : ""
                      }`}
                    style={{ minWidth: 120, borderRadius: "3px 3px 0px 0px" }}
                    onClick={() => setActiveTabs(tab)}
                    title={tab.charAt(0).toUpperCase() + tab.slice(1)}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </button>
                ))}
              </div>
              <button
                className={`tabButtons border-0 fs-13 p-1 px-2`}
                style={{ borderRadius: "3px 3px 0px 0px" }}
                title={!isHalfScreen ? "Full Mode" : "Original Mode"}
                onClick={toggleHalfScreen}
              >
                {isHalfScreen ? <BiCollapse /> : <BiExpand />}
              </button>
            </div>
            <SplitPane
              split="horizontal"
              className="position-relative"
              defaultSize="100%"
            >
              {activeTabs === "properties" ? (
                <SplitPane split="vertical" minSize={20} defaultSize="100%">
                  <div>
                    {loader ? (
                      <div className="hstack justify-content-center h-75">
                        <Spinner />
                      </div>
                    ) : tableData?.length === 0 ? (
                      <div className="hstack justify-content-center h-75">
                        Data not found!
                      </div>
                    ) : (
                      <CodeListPropertiesTableData tableData={tableData} />
                    )}
                  </div>
                </SplitPane>
              ) : activeTabs === "history" ? (
                <SplitPane split="vertical" minSize={20} defaultSize="100%">
                  <div>
                    {loader ? (
                      <div className="hstack justify-content-center">
                        <Spinner />
                      </div>
                    ) : tableData?.length === 0 ? (
                      <div className="hstack justify-content-center">
                        Data not found!
                      </div>
                    ) : (
                      <DocumentHistory />
                    )}
                  </div>
                </SplitPane>
              ) : activeTabs === "recipientDetails" ? (
                <SplitPane split="vertical" minSize={20} defaultSize="100%">
                  <div>
                    <RecipientDetails />
                  </div>
                </SplitPane>
              ) : (
                ""
              )}
            </SplitPane>
          </div>
        </SplitPane>
      </div>
      <CodeListTableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
    </div>
  );
};

export default CodeList;

import React, { useState } from 'react'
import { useDrop } from 'react-dnd';
import { Form } from 'react-bootstrap';
import { GrCut } from 'react-icons/gr';
import { CgCopy } from 'react-icons/cg';
import Draggable from 'react-draggable';
import { GoPaste } from 'react-icons/go';
import { IoDuplicate } from 'react-icons/io5';
import { RiDeleteBin6Line } from 'react-icons/ri';
import "@react-pdf-viewer/core/lib/styles/index.css";
import PDffile from "../../../../../src/Reports.pdf";
import RightClick from '../../../../Hooks/RightClick';
import { ContextMenuTrigger } from 'react-contextmenu';
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const PdfDetailsDesignRight = ({ showName, setShowName, handleDuplicateSignature, deleteSignature, setToggle, selectedSignature, showText, setToggle1, textProperties, handleTextPropertyChange,
    dateSigned, labelBackgroundColor, setDateSigned, setEditTableDates, setJobTitle, setCompanyName, setEmailEdit, setNameTag, setRadioChecks,
    getCurrentDateTime, setToggle3, setCheckBox, setShowText, checkBox, setToggle2, setToggle4, emailEdit, jobTitle, companyName, label, hyperlink,
    setLabel, editTableDates, setHyperlink, radioChecks, userCurrentData, nameTag }) => {

    const [items, setItems] = useState([]);
    const [clipboard, setClipboard] = useState(null);
    const [isDragging, setIsDragging] = useState(false);

    const textStyle = {
        color: textProperties.color,
        fontFamily: textProperties.font,
        position: textProperties.position,
        fontSize: textProperties.fontSize,
        textAlign: textProperties.alignment,
        lineHeight: textProperties.lineHeight + 'px',
        fontWeight: textProperties.bold ? 'bold' : 'normal',
        letterSpacing: textProperties.characterSpacing + 'px',
        fontStyle: textProperties.italic ? 'italic' : 'normal',
        textDecoration: textProperties.underline ? 'underline' : 'none',
    };

    // const handleDuplicate = (index) => {
    //     const itemToDuplicate = items[index];
    //     const newItem = { ...itemToDuplicate, top: itemToDuplicate.top + 10, left: itemToDuplicate.left + 10 };
    //     setItems([...items, newItem]);
    // };

    const handleCut = (index) => {
        const itemToCut = items[index];
        setClipboard(itemToCut);
        const newItems = items.filter((_, i) => i !== index);
        setItems(newItems);
    };

    const handleCopy = (index) => {
        const itemToCopy = items[index];
        setClipboard(itemToCopy);
    };

    const handlePaste = () => {
        if (clipboard) {
            const newItem = { ...clipboard, top: clipboard.top + 10, left: clipboard.left + 10 };
            setItems([...items, newItem]);
            setClipboard(null);
        }
    };

    const handleDelete = (index) => {
        const newItems = items.filter((_, i) => i !== index);
        setItems(newItems);
    };

    const rightClickMenu = {
        ContextId: "uniqueId",
        Menu1: true,
        MenuName1: "Duplicate",
        icons1: <IoDuplicate className="text-white" />,
        className1: "text-white",
        onClick1: handleDuplicateSignature,
        Menu2: true,
        MenuName2: "Cut",
        icons2: <GrCut className="text-white" />,
        className2: "text-white",
        onClick2: handleCut,
        Menu3: true,
        MenuName3: "Copy",
        icons3: <CgCopy className="text-white" />,
        className3: "text-white",
        onClick3: handleCopy,
        Menu4: true,
        MenuName4: "Paste",
        icons4: <GoPaste className="text-white" />,
        className4: "text-white",
        onClick4: handlePaste,
        Menu5: true,
        MenuName5: "Delete",
        icons5: <RiDeleteBin6Line className="text-white" />,
        className5: "text-white",
        onClick5: handleDelete
    };

    const [, drop] = useDrop(() => ({
        accept: 'SIDEBAR_ITEM',
        drop: (item) => {
            switch (item.type) {
                case 'signature':
                    setShowName(true);
                    break;
                case 'initials':
                    setToggle(true);
                    break;
                case 'textbox':
                    setShowText(true);
                    break;
                case 'date signed':
                    setDateSigned(true);
                    break;
                case 'checkbox':
                    setCheckBox(true);
                    break;
                case 'radio':
                    setRadioChecks(true);
                    break;
                case 'name':
                    setNameTag(true);
                    break;
                case 'email':
                    setEmailEdit(true);
                    break;
                case 'title':
                    setJobTitle(true);
                    break;
                case 'company':
                    setCompanyName(true);
                    break;
                case 'editable date':
                    setEditTableDates(true);
                    break;
                case 'Image':
                    setToggle3(true);
                    break;
                case 'label':
                    setLabel(true);
                    break;
                case 'hyperlink':
                    setHyperlink(true);
                    break;
                default:
                    break;
            }
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    }));

    const handleDrag = (e, data) => {
        if (data.deltaX !== 0 || data.deltaY !== 0) {
            setIsDragging(true);
        }
    };

    const handleStop = () => {
        setTimeout(() => setIsDragging(false), 100);
    };

    const handleClick = () => {
        if (!isDragging) {
            setToggle(true);
        }
    };

    const handleClick1 = () => {
        if (!isDragging) {
            setToggle1(true);
        }
    };

    const handleClick2 = () => {
        if (!isDragging) {
            setToggle2(true);
        }
    };

    const handleClick3 = () => {
        if (!isDragging) {
            setToggle4(true);
        }
    };

    console.log(">>>>>>handleDuplicateSignature", handleDuplicateSignature);
    

    return (
        <div ref={drop} className='overflow-auto' style={{ height: "calc(100vh - 115px)" }}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                <div className='position-relative'>
                    <Viewer fileUrl={PDffile} style={{ position: 'relative', height: '100%' }} />
                    {showName && (
                        <>
                            {selectedSignature?.map((item, index) => (
                                <>
                                    <ContextMenuTrigger
                                        id={"uniqueId"}
                                        collect={() => ({ id: "uniqueId" })}
                                        key={item.id}
                                    >
                                        <Draggable onDrag={handleDrag} onStop={handleStop}>
                                            <div onClick={handleClick} className='cursor-pointer allBorder text-dark fs-13 p-1 position-absolute' style={{ border: "2px solid #f1efc994", top: 123, left: 250, zIndex: 1000, background: "#f1efc994" }}>
                                                {userCurrentData?.fullName}
                                            </div>
                                        </Draggable>
                                        <RightClick {...rightClickMenu} />
                                    </ContextMenuTrigger>
                                </>
                            ))}
                        </>
                    )}

                    {showText && (
                        <ContextMenuTrigger
                            id={"uniqueId"}
                            collect={() => ({ id: "uniqueId" })}
                        >
                            <Draggable onDrag={handleDrag} onStop={handleStop}>
                                <div onClick={handleClick1} className='cursor-pointer allBorder position-absolute' style={{ border: "2px solid #f1efc994", top: 123, left: 250, zIndex: 1000, background: "#f1efc994" }}>
                                    <Form.Control
                                        type="text"
                                        className='bg-transparent border-0'
                                        placeholder="Add text here..."
                                        value={textProperties.defaultText}
                                        style={textStyle}
                                        onChange={(e) => handleTextPropertyChange('defaultText', e.target.value)}
                                    />
                                </div>
                            </Draggable>
                            <RightClick {...rightClickMenu} />
                        </ContextMenuTrigger>
                    )}

                    {dateSigned && (
                        <ContextMenuTrigger
                            id={"uniqueId"}
                            collect={() => ({ id: "uniqueId" })}
                        >
                            <Draggable onDrag={handleDrag} onStop={handleStop}>
                                <div onClick={handleClick1} className='cursor-pointer allBorder text-dark fs-13 p-1 position-absolute' style={{ border: "2px solid #f1efc994", top: 10, left: 60, zIndex: 1000, background: "#f1efc994" }}>
                                    {getCurrentDateTime()}
                                </div>
                            </Draggable>
                            <RightClick {...rightClickMenu} />
                        </ContextMenuTrigger>
                    )}

                    {checkBox && (
                        <ContextMenuTrigger
                            id={"uniqueId"}
                            collect={() => ({ id: "uniqueId" })}
                        >
                            <Draggable onDrag={handleDrag} onStop={handleStop}>
                                <div onClick={handleClick2} className='cursor-pointer allBorder text-dark fs-13 p-1 position-absolute' style={{ border: "2px solid #f1efc994", top: 20, left: 70, zIndex: 1000, background: "#f1efc994" }}>
                                    <Form.Check aria-label="option 1"
                                        style={{ width: textProperties.checkboxSize, height: textProperties.checkboxSize, position: textProperties.position }}
                                    />
                                </div>
                            </Draggable>
                            <RightClick {...rightClickMenu} />
                        </ContextMenuTrigger>
                    )}

                    {radioChecks && (
                        <ContextMenuTrigger
                            id={"uniqueId"}
                            collect={() => ({ id: "uniqueId" })}
                        >
                            <Draggable onDrag={handleDrag} onStop={handleStop}>
                                <div onClick={handleClick2} className='cursor-pointer allBorder text-dark fs-13 p-1 position-absolute' style={{ border: "2px solid #f1efc994", top: 30, left: 100, zIndex: 1000, background: "#f1efc994" }}>
                                    <Form.Check type="radio" aria-label="radio 1"
                                        style={{ width: textProperties.checkboxSize, height: textProperties.checkboxSize, position: textProperties.position }}
                                    />
                                </div>
                            </Draggable>
                            <RightClick {...rightClickMenu} />
                        </ContextMenuTrigger>
                    )}

                    {editTableDates && (
                        <ContextMenuTrigger
                            id={"uniqueId"}
                            collect={() => ({ id: "uniqueId" })}
                        >
                            <Draggable>
                                <div className='cursor-pointer allBorder text-dark fs-13 position-absolute' style={{ border: "2px solid #f1efc994", top: 40, left: 200, zIndex: 1000, background: "#f1efc994" }}>
                                    <Form.Control type="date" />
                                </div>
                            </Draggable>
                            <RightClick {...rightClickMenu} />
                        </ContextMenuTrigger>
                    )}

                    {nameTag && (
                        <ContextMenuTrigger
                            id={"uniqueId"}
                            collect={() => ({ id: "uniqueId" })}
                        >
                            <Draggable onDrag={handleDrag} onStop={handleStop}>
                                <div onClick={handleClick3} className='cursor-pointer allBorder text-dark fs-13 p-1 position-absolute' style={{ border: "2px solid #f1efc994", top: 50, left: 120, zIndex: 1000, background: "#f1efc994" }}>
                                    <div
                                        value={textProperties.defaultText}
                                        style={textStyle}
                                        onChange={(e) => handleTextPropertyChange('defaultText', e.target.value)}
                                    >
                                        {userCurrentData?.fullName}
                                    </div>
                                </div>
                            </Draggable>
                            <RightClick {...rightClickMenu} />
                        </ContextMenuTrigger>
                    )}

                    {emailEdit && (
                        <ContextMenuTrigger
                            id={"uniqueId"}
                            collect={() => ({ id: "uniqueId" })}
                        >
                            <Draggable onDrag={handleDrag} onStop={handleStop}>
                                <div onClick={handleClick3} className='cursor-pointer allBorder text-dark fs-13 p-1 position-absolute' style={{ border: "2px solid #f1efc994", top: 60, left: 130, zIndex: 1000, background: "#f1efc994" }}>
                                    <div
                                        value={textProperties.defaultText}
                                        style={textStyle}
                                        onChange={(e) => handleTextPropertyChange('defaultText', e.target.value)}
                                    >
                                        {userCurrentData?.email}
                                    </div>
                                </div>
                            </Draggable>
                            <RightClick {...rightClickMenu} />
                        </ContextMenuTrigger>
                    )}

                    {jobTitle && (
                        <ContextMenuTrigger
                            id={"uniqueId"}
                            collect={() => ({ id: "uniqueId" })}
                        >
                            <Draggable onDrag={handleDrag} onStop={handleStop}>
                                <div onClick={handleClick3} className='cursor-pointer allBorder position-absolute' style={{ border: "2px solid #f1efc994", top: 70, left: 80, zIndex: 1000, background: "#f1efc994" }}>
                                    <Form.Control
                                        type="text"
                                        className='bg-transparent border-0'
                                        placeholder="Add job title here..."
                                        value={textProperties.defaultText}
                                        style={textStyle}
                                        onChange={(e) => handleTextPropertyChange('defaultText', e.target.value)}
                                    />
                                </div>
                            </Draggable>
                            <RightClick {...rightClickMenu} />
                        </ContextMenuTrigger>
                    )}

                    {companyName && (
                        <ContextMenuTrigger
                            id={"uniqueId"}
                            collect={() => ({ id: "uniqueId" })}
                        >
                            <Draggable onDrag={handleDrag} onStop={handleStop}>
                                <div onClick={handleClick3} className='cursor-pointer allBorder position-absolute' style={{ border: "2px solid #f1efc994", top: 80, left: 90, zIndex: 1000, background: "#f1efc994" }}>
                                    <Form.Control
                                        type="text"
                                        className='bg-transparent border-0'
                                        placeholder=" Enter company name"
                                        value={textProperties.defaultText}
                                        style={textStyle}
                                        onChange={(e) => handleTextPropertyChange('defaultText', e.target.value)}
                                    />
                                </div>
                            </Draggable>
                            <RightClick {...rightClickMenu} />
                        </ContextMenuTrigger>
                    )}

                    {label && (
                        <ContextMenuTrigger
                            id={"uniqueId"}
                            collect={() => ({ id: "uniqueId" })}
                        >
                            <Draggable onDrag={handleDrag} onStop={handleStop}>
                                <div onClick={handleClick1} className='cursor-pointer allBorder position-absolute' style={{ border: "2px solid #f1efc994", top: 90, left: 125, zIndex: 1000, background: "#f1efc994" }}>
                                    <Form.Control
                                        as="textarea"
                                        className='bg-transparent border-0'
                                        placeholder="Enter label"
                                        value={textProperties.defaultText}
                                        style={{ ...textStyle, backgroundColor: labelBackgroundColor }}
                                        onChange={(e) => handleTextPropertyChange('defaultText', e.target.value)}
                                    />
                                </div>
                            </Draggable>
                            <RightClick {...rightClickMenu} />
                        </ContextMenuTrigger>
                    )}

                    {hyperlink && (
                        <ContextMenuTrigger
                            id={"uniqueId"}
                            collect={() => ({ id: "uniqueId" })}
                        >
                            <Draggable onDrag={handleDrag} onStop={handleStop}>
                                <div onClick={handleClick1} className='cursor-pointer allBorder position-absolute' style={{ border: "2px solid #f1efc994", top: 100, left: 137, zIndex: 1000, background: "#f1efc994" }}>
                                    <Form.Control
                                        type='text'
                                        className='bg-transparent border-0'
                                        placeholder="Enter hyperlink"
                                        value={textProperties.defaultText}
                                        style={textStyle}
                                        onChange={(e) => handleTextPropertyChange('defaultText', e.target.value)}
                                    />
                                </div>
                            </Draggable>
                            <RightClick {...rightClickMenu} />
                        </ContextMenuTrigger>
                    )}
                </div>
            </Worker>
        </div>
    )
}

export default PdfDetailsDesignRight

import React, { useState } from 'react';
import { TbLogs } from 'react-icons/tb';
import SignatureType from './SignatureType';
import SignatureDraw from './SignatureDraw';
import SinatureUpload from './SinatureUpload';
import { Offcanvas, Row } from 'react-bootstrap';
import { GrStatusWarning } from 'react-icons/gr';
import { MdOutlineManageHistory } from 'react-icons/md';

const SignatureEditor = ({ Show, Hide, Title, Submit, handleSignatureChange, userCurrentData }) => {
    const [activeTab, setActiveTab] = useState("SignatureType");

    const renderActiveTab = () => {
        switch (activeTab) {
            case "SignatureType":
                return <SignatureType handleSignatureChange={handleSignatureChange} userCurrentData={userCurrentData} />;
            case "SignatureDraw":
                return <SignatureDraw />;
            case "SinatureUpload":
                return <SinatureUpload />;
            default:
                return null;
        }
    };

    return (

        <>
            <Offcanvas show={Show} onHide={Hide} placement="end" style={{ width: '600px' }}>
                <Offcanvas.Header>
                    <Offcanvas.Title>{Title} </Offcanvas.Title>
                    <div onClick={Hide} className="close_modal" title="Close">
                        X
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row className='border-bottom mb-2'>
                        <div className="hstack gap-2 mb-2">
                            <button
                                className={`w-100 hstack gap-1 justify-content-center tabButtons border rounded-1 fs-13 p-1 ${activeTab === "SignatureType" ? "active" : ""}`}
                                onClick={() => setActiveTab("SignatureType")}
                            >
                                <MdOutlineManageHistory />
                                <div>Type</div>
                            </button>
                            <button
                                className={`w-100 hstack gap-1 justify-content-center tabButtons border rounded-1 fs-13 p-1 ${activeTab === "SignatureDraw" ? "active" : ""}`}
                                onClick={() => setActiveTab("SignatureDraw")}
                            >
                                <TbLogs />
                                <div>Draw</div>
                            </button>
                            <button
                                className={`w-100 hstack gap-1 justify-content-center tabButtons border rounded-1 fs-13 p-1 ${activeTab === "SinatureUpload" ? "active" : ""}`}
                                onClick={() => setActiveTab("SinatureUpload")}
                            >
                                <GrStatusWarning />
                                <div>Upload</div>
                            </button>
                        </div>
                        {renderActiveTab()}
                    </Row>
                    <div className="hstack gap-2 justify-content-end m-0">
                        <button className="p-1 px-3 border rounded-1 text-dark bg-danger-subtle fs-13">
                            Cancel
                        </button>
                        <button className="p-1 px-3 border rounded-1 text-white bg-color fs-13" onClick={() => handleSignatureChange && handleSignatureChange(userCurrentData?.fullName)}>
                            {Submit}
                        </button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default SignatureEditor;

import React from 'react'
import {Form, InputGroup, Offcanvas} from 'react-bootstrap'

const NamelabelEtcOffcanvas = ({ Show, Hide, Title, textProperties, onTextPropertyChange }) => {
    return (
        <div>
            <Offcanvas show={Show} onHide={Hide} placement="end" style={{ width: '400px' }}>
                <Offcanvas.Header>
                    <Offcanvas.Title>{Title} </Offcanvas.Title>
                    <div onClick={Hide} className="close_modal" title="Close">
                        X
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        {/* <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={4}>Position</Form.Label>
                            <Col sm={4}>
                                <Form.Control
                                    type="number"
                                    placeholder="X"
                                    value={textProperties.position.x}
                                    onChange={(e) => onTextPropertyChange('position', { ...textProperties.position, x: parseFloat(e.target.value) })}
                                />
                            </Col>
                            <Col sm={4}>
                                <Form.Control
                                    type="number"
                                    placeholder="Y"
                                    value={textProperties.position.y}
                                    onChange={(e) => onTextPropertyChange('position', { ...textProperties.position, y: parseFloat(e.target.value) })}
                                />
                            </Col>
                        </Form.Group> */}
                        <Form.Group className="mb-3">
                            <Form.Label className='fw-bold' style={{ fontSize: 14 }}>Font</Form.Label>
                            <Form.Select
                                value={textProperties.font}
                                onChange={(e) => onTextPropertyChange('font', e.target.value)}>
                                <option>Helvetica</option>
                                <option>Arial</option>
                                <option>Times New Roman</option>
                                <option>Courier New</option>
                                <option>Verdana</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className='fw-bold' style={{ fontSize: 14 }}>Font Size</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="number"
                                    value={textProperties.fontSize}
                                    onChange={(e) => onTextPropertyChange('fontSize', parseInt(e.target.value))}
                                />
                                <InputGroup.Text>px</InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className='fw-bold' style={{ fontSize: 14 }}>Line Height</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="number"
                                    value={textProperties.lineHeight}
                                    onChange={(e) => onTextPropertyChange('lineHeight', parseInt(e.target.value))}
                                />
                                <InputGroup.Text>px</InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className='fw-bold' style={{ fontSize: 14 }}>Text Formatting</Form.Label>
                            <div className="d-flex justify-content-between">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text
                                        variant={textProperties.bold ? "dark" : "light"}
                                        onClick={() => onTextPropertyChange('bold', !textProperties.bold)}
                                    ><i className="fa-solid fa-bold"></i></InputGroup.Text>
                                    <InputGroup.Text
                                        variant={textProperties.italic ? "dark" : "light"}
                                        onClick={() => onTextPropertyChange('italic', !textProperties.italic)}
                                    ><i className="fa-solid fa-italic"></i></InputGroup.Text>
                                    <InputGroup.Text
                                        variant={textProperties.underline ? "dark" : "light"}
                                        onClick={() => onTextPropertyChange('underline', !textProperties.underline)}
                                    ><i className="fa-solid fa-underline"></i></InputGroup.Text>
                                </InputGroup>
                                <div>
                                    <Form.Control
                                        type="color"
                                        value={textProperties.color}
                                        onChange={(e) => onTextPropertyChange('color', e.target.value)}
                                    />
                                </div>
                            </div>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label className='fw-bold' style={{ fontSize: 14 }}>Alignment</Form.Label>
                            <div className="d-flex justify-content-between">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text
                                        style={{
                                            backgroundColor: textProperties.alignment === "left" ? "#103c5e" : "#103c5e00",
                                            color: textProperties.alignment === "left" ? "white" : "black",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => onTextPropertyChange('alignment', "left")}
                                    >
                                        <i className="fa-solid fa-align-left" title='left'></i>
                                    </InputGroup.Text>

                                    <InputGroup.Text
                                        style={{
                                            backgroundColor: textProperties.alignment === "center" ? "#103c5e" : "#103c5e00",
                                            color: textProperties.alignment === "center" ? "white" : "black",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => onTextPropertyChange('alignment', "center")}
                                    >
                                        <i className="fa-solid fa-align-center" title='center'></i>
                                    </InputGroup.Text>

                                    <InputGroup.Text
                                        style={{
                                            backgroundColor: textProperties.alignment === "right" ? "#103c5e" : "#103c5e00",
                                            color: textProperties.alignment === "right" ? "white" : "black",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => onTextPropertyChange('alignment', "right")}
                                    >
                                        <i className="fa-solid fa-align-right" title='right'></i>
                                    </InputGroup.Text>
                                </InputGroup>

                                <div>
                                    <InputGroup.Text
                                        style={{
                                            backgroundColor: textProperties.rtl ? "#103c5e" : "#f8f9fa",
                                            color: textProperties.rtl ? "white" : "black",
                                            cursor: "pointer",
                                            padding: "2px 5px"
                                        }}
                                        onClick={() => onTextPropertyChange('rtl', !textProperties.rtl)}
                                    >
                                        RTL
                                    </InputGroup.Text>
                                </div>
                            </div>
                        </Form.Group>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default NamelabelEtcOffcanvas

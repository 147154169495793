import React, { useState } from 'react'
import { Card, Offcanvas } from 'react-bootstrap'

const SidebarImageDesign = ({ Show, Hide, Title, Submit }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <>
      <Offcanvas show={Show} onHide={Hide} placement="end" style={{ width: '500px' }}>
        <Offcanvas.Header>
          <Offcanvas.Title>{Title} </Offcanvas.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card style={{ padding: '10px', backgroundColor: "#e4e9ed00" }} className="mb-3">
            <Card.Body className="text-center">
              <Card.Title>Upload Image</Card.Title>
              <Card.Text>
                {selectedFile ? selectedFile.name : 'No file selected'}
              </Card.Text>
              <input
                type="file"
                style={{ display: 'none' }}
                id="file-upload"
                onChange={handleFileChange}
              />
              <button className="p-2 px-5 border rounded-1 text-white bg-color fs-13" onClick={() => document.getElementById('file-upload').click()}>
                Choose File
              </button>
            </Card.Body>
          </Card>
          <div className="hstack gap-2 justify-content-end m-0">
            <button
              className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
            >
              {Submit}
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default SidebarImageDesign

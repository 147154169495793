import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const Dashboard = ({userCurrentData}) => {
  const [greeting, setGreeting] = useState("");

  const getGreeting = () => {
    const currentTime = new Date().getHours();
    let greeting;

    if (currentTime >= 5 && currentTime < 12) {
      greeting = "Good Morning";
    } else if (currentTime >= 12 && currentTime < 18) {
      greeting = "Good Afternoon";
    } else {
      greeting = "Good Evening";
    }
    setGreeting(greeting);
  };

  useEffect(() => {
    getGreeting();
  }, []);
  return (
    <>
      <div className="border-bottom">
        <div className="text-center py-2">
          <h5 className="m-0">
            {greeting}, {userCurrentData?.fullName}
          </h5>
          <Form.Label className="m-0">Welcome to Your Digital Signature Dashboard.</Form.Label>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

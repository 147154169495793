import React, { useState } from "react";
import Moment from "react-moment";
import { Table } from "react-bootstrap";
import Pagination from "../../../Hooks/Pagination";

const RecipientDetails = () => {

    const RecentDetailsData = [
        {
            recipientDetails: "Satish Kumar",
            deliveryMode: "email",
            lastActivity: "2024-07-31 13:00:00",
            status: "Completed",
            authentication: "192.168.1.1",
        },
        {
            recipientDetails: "Satish Kumar",
            deliveryMode: "email",
            lastActivity: "	2024-07-31 13:00:00",
            status: "Signed",
            authentication: "192.168.1.2",
        },
        {
            recipientDetails: "Satish Kumar",
            deliveryMode: "email",
            lastActivity: "	2024-07-31 13:00:00",
            status: "Completed",
            authentication: "192.168.1.3",
        },
    ];

    const [pageNumber, setPageNumber] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const result = RecentDetailsData?.slice(startIndex, endIndex);
    const totalPage = Math.ceil(RecentDetailsData?.length / itemsPerPage);

    return (
        <>
            <Table className="text-nowrap mb-1" hover bordered>
                <thead className="position-sticky z-1" style={{ top: -1 }}>
                    <tr>
                        <th >Recipient Details</th>
                        <th style={{ width: 150 }}>Delivery Mode</th>
                        <th >Last Activity</th>
                        <th >
                            <div>status</div>
                        </th>
                        <th>Authentication</th>
                    </tr>
                </thead>
                <tbody>
                    {result?.map((item) => (
                        <tr className="position-relative CP">
                            <td>{item.recipientDetails}</td>
                            <td>{item.deliveryMode}</td>
                            <td>
                                <Moment format="DD MMM YYYY | HH:mm:ss">
                                    {item.lastActivity}
                                </Moment>
                            </td>
                            <td>{item.status}</td>
                            <td>{item.authentication}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className="position-sticky bottom-0">
                <Pagination
                    totalPage={totalPage}
                    pageNumber={pageNumber}
                    itemsPerPage={itemsPerPage}
                    totalItems={RecentDetailsData?.length}
                    setPageNumber={setPageNumber}
                    setItemsPerPage={setItemsPerPage}
                />
            </div>
        </>
    );
};

export default RecipientDetails;

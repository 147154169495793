import React, { useEffect, useRef, useState } from "react";
import { HiMiniChevronDown } from "react-icons/hi2";
import { TiHomeOutline } from "react-icons/ti";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import {
  CodeList,
  FailedStatus,
  LogsHistory,
  RecentActivities,
  StudyDetails,
  StudyMetadata,
  StudyProtocol,
  TabHistory,
  UserDetails,
} from "./TabsComponent";
import Swal from "sweetalert2";
import Dashboard from "../Pages/Dashboard";
import DnDContext from "./DraggableTab/DnDContext";
import DraggableTab from "./DraggableTab/DraggableTab";
import PdfBuilder from "./TabsComponent/CodeList/PdfEditor/PdfBuilder";
import AddNewDocument from "./TabsComponent/CodeList/AddNewDocument/AddNewDocument";
import SignatureEditor from "./TabsComponent/CodeList/PdfEditor/SignatureSection/SignatureEditor";
import TemplatesDesign from "./TabsComponent/CodeList/Templates/TemplatesDesign";

const MainPanel = ({
  setTabs,
  tabs,
  handleAddNewTab,
  userCurrentData,
  currentUserData,
}) => {
  const [open, setOpen] = useState(false);
  const toggleRef = useRef(null);
  const tabContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(tabs.length > 1);
  const handleActiveTab = (tabId) => {
    const updatedTabs = tabs.map((tab) => ({ ...tab, status: "inactive" }));
    updatedTabs.forEach((element) => {
      if (element.tabId === tabId) {
        element.status = "active";
      }
    });
    setTabs(updatedTabs);
  };

  const handleInactiveAllTab = () => {
    const updatedTabs = tabs.map((tab) => ({ ...tab, status: "inactive" }));
    setTabs(updatedTabs);
  };

  const handleDeleteTab = (tabId) => {
    // Swal.fire({
    //   title: "You want to Close this Tab",
    //   icon: "warning",
    //   showCancelButton: true,
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: "Yes, close it!",
    // }).then(async (result) => {
    //   if (result.isConfirmed) {
    const updatedTabs = tabs.filter((tab) => tab.tabId !== tabId);
    setTabs(updatedTabs);
    //   }
    // });
  };

  const handleDeleteAllTab = () => {
    Swal.fire({
      title: "You want to Close all Tab",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setTabs([]);
      }
    });
  };

  const handleCloseOtherTab = (item) => {
    Swal.fire({
      title: `You want to Close other Tab`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let updatedTabs = { ...item, status: "active" };
        setTabs([updatedTabs]);
      }
    });
  };

  const handleCloseRightTabs = (index) => {
    Swal.fire({
      title: `You want to Close all Right Tab`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updatedTabs = tabs.slice(0, index + 1);
        const deletedArray = tabs.slice(index + 1);
        const checkStatus = deletedArray?.some(
          (item) => item.status === "active"
        );
        if (checkStatus) {
          updatedTabs[updatedTabs?.length - 1].status = "active";
          setTabs(updatedTabs);
        } else {
          setTabs(updatedTabs);
        }
      }
    });
  };

  const handleCloseLeftTabs = (index) => {
    Swal.fire({
      title: `You want to Close all left Tab`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updatedTabs = tabs.slice(index);
        const deletedArray = tabs.slice(0, index);
        const checkStatus = deletedArray?.some(
          (item) => item.status === "active"
        );
        if (checkStatus) {
          updatedTabs[0].status = "active";
          setTabs(updatedTabs);
        } else {
          setTabs(updatedTabs);
        }
      }
    });
  };

  const handleMoveTab = (fromIndex, toIndex) => {
    setTabs((prevTabs) => {
      const updatedTabs = [...prevTabs];
      const [movedTab] = updatedTabs.splice(fromIndex, 1);
      updatedTabs.splice(toIndex, 0, movedTab);
      return updatedTabs;
    });
  };

  const handleMoveTabLeft = (index) => {
    if (index > 0) {
      handleMoveTab(index, index - 1);
    }
  };

  const handleMoveTabRight = (index) => {
    if (index < tabs.length - 1) {
      handleMoveTab(index, index + 1);
    }
  };

  const handleScrollTabLeft = () => {
    tabContainerRef?.current?.scrollBy({ left: -150, behavior: "smooth" });
    updateScrollState();
  };

  const handleScrollTabRight = () => {
    tabContainerRef?.current?.scrollBy({ left: 150, behavior: "smooth" });
    updateScrollState();
  };

  const updateScrollState = () => {
    const scrollLeft = tabContainerRef?.current?.scrollLeft;
    const scrollWidth = tabContainerRef?.current?.scrollWidth;
    const clientWidth = tabContainerRef?.current?.clientWidth;
    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
  };

  const activeTab = tabs.find((item) => item.status === "active");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (toggleRef?.current && !toggleRef?.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <div
        className="overflow-auto border rounded-1 position-relative"
        style={{ height: "calc(100vh - 80px)" }}
      >
        <div
          ref={toggleRef}
          className="border-bottom tabbars hstack gap-2 px-2 pb-0 bg-light"
          style={{ padding: 7 }}
        >
          {tabs?.length > 0 && (
            <HiMiniChevronDown
              onClick={() => setOpen(!open)}
              className={open && "active"}
            />
          )}
          <TabHistory
            open={open}
            tabs={tabs}
            handleMoveTab={handleMoveTab}
            handleActiveTab={handleActiveTab}
            handleDeleteTab={handleDeleteTab}
            handleDeleteAllTab={handleDeleteAllTab}
          />
          <TiHomeOutline
            className={!activeTab && "active"}
            onClick={handleInactiveAllTab}
          />
          {tabs?.length >= 8 && (
            <MdOutlineKeyboardArrowLeft
              className={canScrollLeft && "active"}
              onClick={handleScrollTabLeft}
            />
          )}
          <DnDContext>
            <div
              ref={tabContainerRef}
              onScroll={updateScrollState}
              className="hstack gap-2 w-100 overflow-auto blank"
            >
              {tabs?.map((item, index) => (
                <DraggableTab
                  key={item.tabId}
                  item={item}
                  index={index}
                  handleMoveTab={handleMoveTab}
                  handleActiveTab={handleActiveTab}
                  handleDeleteTab={handleDeleteTab}
                  handleCloseOtherTab={handleCloseOtherTab}
                  handleCloseRightTabs={handleCloseRightTabs}
                  handleCloseLeftTabs={handleCloseLeftTabs}
                  handleDeleteAllTab={handleDeleteAllTab}
                  handleMoveTabLeft={handleMoveTabLeft}
                  handleMoveTabRight={handleMoveTabRight}
                />
              ))}
            </div>
          </DnDContext>
          {tabs?.length >= 8 && (
            <MdOutlineKeyboardArrowRight
              className={canScrollRight && "active"}
              onClick={handleScrollTabRight}
            />
          )}
        </div>
        {activeTab?.tabId === "dashBoardID" ? (
          <StudyDetails />
        ) : activeTab?.tabId === "studyMetadataID" ? (
          <StudyMetadata />
        ) : activeTab?.tabId === "studyProtocolID" ? (
          <StudyProtocol />
        ) : activeTab?.tabId === "codeListID" ? (
          <CodeList handleAddNewTab={handleAddNewTab} />
        ) : activeTab?.tabId === "userId" ? (
          <UserDetails
            handleAddNewTab={handleAddNewTab}
            userCurrentData={userCurrentData}
            currentUserData={currentUserData}
          />
        ) : activeTab?.tabId === "recentActivityId" ? (
          <RecentActivities />
        ) : activeTab?.tabId === "addNewDocumentId" ? (
          <AddNewDocument handleAddNewTab={handleAddNewTab} />
        ) : activeTab?.tabId === "pdfBuilderId" ? (
          <PdfBuilder userCurrentData={userCurrentData} />
        ) : activeTab?.tabId === "templatesID" ? (
          <TemplatesDesign handleAddNewTab={handleAddNewTab} />
        ) : activeTab?.tabId === "signatureId" ? (
          <SignatureEditor handleAddNewTab={handleAddNewTab} />
        ) : activeTab?.tabId === "historyId" ? (
          <LogsHistory />
        ) : activeTab?.tabId === "failedStatusId" ? (
          <FailedStatus />
        ) : (
          <Dashboard userCurrentData={userCurrentData} />
        )}
      </div>
    </>
  );
};

export default MainPanel;

import React, { useState } from "react";
import Moment from "react-moment";
import { Table } from "react-bootstrap";
import Pagination from "../../../Hooks/Pagination";

const DocumentHistory = () => {

    const DocumentHistorys = [
        {
            serialNo: 1,
            dateTime: "2024-07-31 12:00:00",
            ipAddress: "192.168.1.1",
            userActivity: "Document has been completed",
            action: "Completed"
        },
        {
            serialNo: 2,
            dateTime: "2024-07-31 12:30:00",
            ipAddress: "192.168.1.2",
            userActivity: "	Satish Kumar signed the document",
            action: "Signed"
        },
        {
            serialNo: 3,
            dateTime: "2024-07-31 13:00:00",
            ipAddress: "192.168.1.3",
            userActivity: "	Satish Kumar signed the document",
            action: "Completed"
        },
    ];

    const [pageNumber, setPageNumber] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const result = DocumentHistorys?.slice(startIndex, endIndex);
    const totalPage = Math.ceil(DocumentHistorys?.length / itemsPerPage);

    return (
        <>
            <Table className="text-nowrap mb-1" hover bordered>
                <thead className="position-sticky z-1" style={{ top: -1 }}>
                    <tr>
                        <th style={{ width: 40 }}>S No.</th>
                        <th>Date | Time</th>
                        <th>IP Address</th>
                        <th>User Activity</th>
                        <th style={{ width: 60 }}>
                            <div className="text-center">Action</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {result?.map((item, index) => (
                        <tr className="position-relative CP">
                            <td>{index + 1}</td>
                            <td>
                                <Moment format="DD MMM YYYY | HH:mm:ss">
                                    {item.dateTime}
                                </Moment>
                            </td>
                            <td>{item.ipAddress}</td>
                            <td>{item.userActivity}</td>
                            <td>{item.action}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className="position-sticky bottom-0">
                <Pagination
                    totalPage={totalPage}
                    pageNumber={pageNumber}
                    itemsPerPage={itemsPerPage}
                    totalItems={DocumentHistorys?.length}
                    setPageNumber={setPageNumber}
                    setItemsPerPage={setItemsPerPage}
                />
            </div>
        </>
    );
};

export default DocumentHistory;

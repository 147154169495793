import React, { useState } from 'react'
import { format } from 'date-fns';
import TextEditor from './TextEditor';
import { DndProvider } from 'react-dnd';
import PdfEditorSidebar from './PdfEditorSidebar';
import UseToggle from '../../../../Hooks/UseToggle';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PdfDetailsDesignRight from './PdfDetailsDesignRight';
import { Col, Container, Form, Row } from 'react-bootstrap';
import SignatureEditor from './SignatureSection/SignatureEditor';
import RadioChecksOffcanvas from './RadioChecks/RadioChecksOffcanvas';
import NamelabelEtcOffcanvas from './NameLabelEtcOffCanvas.js/NamelabelEtcOffcanvas';

const PdfBuilder = ({ userCurrentData }) => {
  const [label, setLabel] = useState(false);
  const [nameTag, setNameTag] = useState(false);
  const [showName, setShowName] = useState(false);
  const [showText, setShowText] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [jobTitle, setJobTitle] = useState(false);
  const [emailEdit, setEmailEdit] = useState(false);
  const [imageEdit, setImageEdit] = useState(false);
  const [hyperlink, setHyperlink] = useState(false);
  const [dateSigned, setDateSigned] = useState(false);
  const [radioChecks, setRadioChecks] = useState(false);
  const [companyName, setCompanyName] = useState(false);
  const [editTableDates, setEditTableDates] = useState(false);
  const [selectedDateFormat, setSelectedDateFormat] = useState('');
  const [selectedTimeFormat, setSelectedTimeFormat] = useState('');
  const [selectedSignature, setSelectedSignature] = useState([{ id: 0 }]);
  const [labelBackgroundColor, setLabelBackgroundColor] = useState('#ffffff');
  const { toggle, setToggle, toggle1, setToggle1, toggle2, setToggle2, toggle3, setToggle3, toggle4, setToggle4 } = UseToggle();

  const [textProperties, setTextProperties] = useState({
    rtl: false,
    bold: false,
    fontSize: 13,
    italic: false,
    lineHeight: 15,
    defaultText: '',
    dataSyncTag: '',
    underline: false,
    color: '#000000',
    checkboxSize: 20,
    font: 'Helvetica',
    alignment: 'left',
    characterSpacing: 0,
    position: { x: 0, y: 0 },
  });

  const handleTextPropertyChange = (property, value) => {
    setTextProperties((prevProps) => ({
      ...prevProps,
      [property]: value,
    }));
  };

  const handleSignatureChange = (name) => {
    setSelectedSignature(name)
  }

  const handleDuplicateSignature = () => {
    setSelectedSignature([...selectedSignature, { id: selectedSignature.length }]);
};

const deleteSignature = (id) => {
  setSelectedSignature(selectedSignature.filter(item => item.id !== id));
};

  const handleDateAndTimeChange = (dateFormat, timeFormat) => {
    setSelectedDateFormat(dateFormat);
    setSelectedTimeFormat(timeFormat);
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const formattedDate = selectedDateFormat ? format(now, selectedDateFormat) : format(now, 'dd/MM/yyyy');
    const formattedTime = selectedTimeFormat ? format(now, selectedTimeFormat) : '';
    return `${formattedDate} ${formattedTime}`.trim();
  };
 console.log(">>>>>selectedSignature", selectedSignature);
 
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Container fluid>
          <Row>
            <Form.Group as={Col} md={3}>
              <PdfEditorSidebar
                toggle3={toggle3}
                showText={showText}
                setLabel={setLabel}
                setToggle={setToggle}
                setNameTag={setNameTag}
                dateSigned={dateSigned}
                setToggle3={setToggle3}
                setJobTitle={setJobTitle}
                setShowName={setShowName}
                setShowText={setShowText}
                setCheckBox={setCheckBox}
                setHyperlink={setHyperlink}
                setEmailEdit={setEmailEdit}
                setImageEdit={setImageEdit}
                setDateSigned={setDateSigned}
                setCompanyName={setCompanyName}
                setRadioChecks={setRadioChecks}
                setEditTableDates={setEditTableDates}
                setLabelBackgroundColor={setLabelBackgroundColor}
              />
            </Form.Group>
            <Form.Group as={Col} md={9} className='p-0'>
              <PdfDetailsDesignRight
                label={label}
                nameTag={nameTag}
                showName={showName}
                showText={showText}
                jobTitle={jobTitle}
                checkBox={checkBox}
                setLabel={setLabel}
                hyperlink={hyperlink}
                setToggle={setToggle}
                imageEdit={imageEdit}
                emailEdit={emailEdit}
                setNameTag={setNameTag}
                dateSigned={dateSigned}
                setToggle1={setToggle1}
                setToggle2={setToggle2}
                setToggle4={setToggle4}
                setToggle3={setToggle3}
                companyName={companyName}
                radioChecks={radioChecks}
                setShowName={setShowName}
                setShowText={setShowText}
                setCheckBox={setCheckBox}
                setJobTitle={setJobTitle}
                setHyperlink={setHyperlink}
                setEmailEdit={setEmailEdit}
                setDateSigned={setDateSigned}
                setCompanyName={setCompanyName}
                setRadioChecks={setRadioChecks}
                editTableDates={editTableDates}
                textProperties={textProperties}
                userCurrentData={userCurrentData}
                deleteSignature={deleteSignature}
                setEditTableDates={setEditTableDates}
                selectedSignature={selectedSignature}
                getCurrentDateTime={getCurrentDateTime}
                labelBackgroundColor={labelBackgroundColor}
                handleDuplicateSignature={handleDuplicateSignature}
                handleTextPropertyChange={handleTextPropertyChange}
              />
            </Form.Group>
          </Row>
        </Container>
      </DndProvider>
      <SignatureEditor
        Show={!toggle}
        Hide={setToggle}
        Submit={`Save and Use`}
        Title={"Signature Properties"}
        userCurrentData={userCurrentData}
        handleSignatureChange={handleSignatureChange}
      />

      <TextEditor
        label={label}
        Show={!toggle1}
        Hide={setToggle1}
        showText={showText}
        dateSigned={dateSigned}
        Title={"Textbox Settings"}
        textProperties={textProperties}
        onDateAndTimeChange={handleDateAndTimeChange}
        onTextPropertyChange={handleTextPropertyChange}
        setLabelBackgroundColor={setLabelBackgroundColor}
      />

      <RadioChecksOffcanvas
        Show={!toggle2}
        Hide={setToggle2}
        checkBox={checkBox}
        radioChecks={radioChecks}
        textProperties={textProperties}
        Title={"Radio & Checks settings"}
        onTextPropertyChange={handleTextPropertyChange}
      />

      <NamelabelEtcOffcanvas
        Show={!toggle4}
        Hide={setToggle4}
        Title={"Name Settings"}
        textProperties={textProperties}
        onTextPropertyChange={handleTextPropertyChange}
      />
    </>
  )
}

export default PdfBuilder

import React, { useState} from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

const SignatureType = ({ handleSignatureChange, userCurrentData}) => {
  const defaultName = userCurrentData?.fullName
  const [name, setName] = useState(defaultName);
  const [selectedSignature, setSelectedSignature] = useState(0);

  const handleInputChange = (e) => {
    setName(e.target.value);
  };

  const handleSignatureClick = (index) => {
    setSelectedSignature(index);
    if (handleSignatureChange) {
      handleSignatureChange(name); 
    }
  };

  const signatureStyles = [
    { fontFamily: 'Cursive', fontSize: '22px' },
    { fontFamily: 'Sans-Serif', fontSize: '22px' },
    { fontFamily: 'Trebuchet MS', fontSize: '22px' },
    { fontFamily: 'Monospace', fontSize: '22px' },
  ];

  const renderSignatures = (name) => {
    return signatureStyles.map((style, index) => (
      <Col md={6} key={index}>
        <Card
          className={`mb-3 custom-cursor p-0 ${selectedSignature === index ? 'border-success' : ''}`}
          style={{ height: 100, cursor: 'pointer' }}
          onClick={() => handleSignatureClick(index)}
        >
          <Card.Body className="d-flex align-items-center justify-content-center">
            <div style={style}>{name}</div>
          </Card.Body>
        </Card>
      </Col>
    ));
  };

  return (
    <div>
      <Row>
        <Col md={3} className="text-end">
          <Form.Label className="fs-13">
            <b>Your Name:</b>
          </Form.Label>
        </Col>
        <Col md={9}>
          <Form.Control type="text" placeholder="Your Name" value={name} onChange={handleInputChange} className='border-success' />
        </Col>
      </Row>
      <Row className="mt-3">
        {name && renderSignatures(name)}
      </Row>
      {selectedSignature !== null && (
        <Row className="mt-3">
          <Col>
            <div className="text-center">
              <h5>Selected Signature</h5>
              <div style={{ fontFamily: signatureStyles[selectedSignature].fontFamily, fontSize: '20px', background: "#f1efc9" }}>
                {name}
              </div>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default SignatureType;

import React, { useEffect, useState } from 'react';
import { Offcanvas, Form, InputGroup } from 'react-bootstrap';

const TextEditor = ({ Show, Hide, Title, textProperties, onTextPropertyChange, dateSigned, showText, onDateAndTimeChange, label, setLabelBackgroundColor }) => {

    const [selectedDateFormat, setSelectedDateFormat] = useState('');
    const [selectedTimeFormat, setSelectedTimeFormat] = useState('');

    const dateFormats = [
        'dd/MM/yyyy',
        'MM/dd/yyyy',
        'yyyy/MM/dd',
        'dd-MMM-yyyy',
        'MM-dd-yyyy',
        'yyyy-MM-dd'
    ];

    const timeFormats = [
        'hh:mm a',
        'h:mm a',
        'HH:mm',
        'H:mm'
    ];

    const handleDateFormatChange = (e) => {
        setSelectedDateFormat(e.target.value);
        onTextPropertyChange('dateFormat', e.target.value);
    };

    const handleTimeFormatChange = (e) => {
        setSelectedTimeFormat(e.target.value);
        onTextPropertyChange('timeFormat', e.target.value);
    };

    const handleColorChange = (event) => {
        setLabelBackgroundColor(event.target.value);
    };

    useEffect(() => {
        onDateAndTimeChange(selectedDateFormat, selectedTimeFormat);
    }, [selectedDateFormat, selectedTimeFormat, onDateAndTimeChange]);

    return (
        <div>
            <Offcanvas show={Show} onHide={Hide} placement="end" style={{ width: '330px' }}>
                <Offcanvas.Header>
                    <Offcanvas.Title>{Title} </Offcanvas.Title>
                    <div onClick={Hide} className="close_modal" title="Close">
                        X
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {dateSigned && (
                        <>
                            <Form.Group className="mb-2">
                                <Form.Label className='fw-bold' style={{ fontSize: 14 }}>Date Format</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={selectedDateFormat}
                                    onChange={handleDateFormatChange}>
                                    <option value="">Select date format</option>
                                    {dateFormats?.map((format, index) => (
                                        <option key={index} value={format}>{format}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label className='fw-bold' style={{ fontSize: 14 }}>Time Format</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={selectedTimeFormat}
                                    onChange={handleTimeFormatChange}>
                                    <option value="">Select time format</option>
                                    {timeFormats?.map((format, index) => (
                                        <option key={index} value={format}>{format}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </>
                    )}
                    {(showText || label) && (
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label className='fw-bold' style={{ fontSize: 14 }}>Default text</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Add text here..."
                                    value={textProperties.defaultText}
                                    onChange={(e) => onTextPropertyChange('defaultText', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className='fw-bold' style={{ fontSize: 14 }}>Data Sync Tag</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter data sync tag"
                                    value={textProperties.dataSyncTag}
                                    onChange={(e) => onTextPropertyChange('dataSyncTag', e.target.value)}
                                />
                            </Form.Group>
                        </>
                    )}

                    {/* <Form.Group className="mb-3">
                        <Form.Label className='fw-bold' style={{ fontSize: 14 }}>Position</Form.Label>
                        <div className='d-flex gap-2'>
                            <Form.Control
                                type="number"
                                placeholder="X"
                                value={textProperties.position.x}
                                onChange={(e) => onTextPropertyChange('position', { ...textProperties.position, x: parseFloat(e.target.value) })}
                            />
                            <Form.Control
                                type="number"
                                placeholder="Y"
                                value={textProperties.position.y}
                                onChange={(e) => onTextPropertyChange('position', { ...textProperties.position, y: parseFloat(e.target.value) })}
                            />
                        </div>
                    </Form.Group> */}
                    <Form.Group className="mb-3">
                        <Form.Label className='fw-bold' style={{ fontSize: 14 }}>Font</Form.Label>
                        <Form.Select
                            value={textProperties.font}
                            onChange={(e) => onTextPropertyChange('font', e.target.value)}>
                            <option>Helvetica</option>
                            <option>Arial</option>
                            <option>Times New Roman</option>
                            <option>Courier New</option>
                            <option>Verdana</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className='fw-bold' style={{ fontSize: 14 }}>Font Size</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="number"
                                value={textProperties.fontSize}
                                onChange={(e) => onTextPropertyChange('fontSize', parseInt(e.target.value))}
                            />
                            <InputGroup.Text>px</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className='fw-bold' style={{ fontSize: 14 }}>Line Height</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="number"
                                value={textProperties.lineHeight}
                                onChange={(e) => onTextPropertyChange('lineHeight', parseInt(e.target.value))}
                            />
                            <InputGroup.Text>px</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className='fw-bold' style={{ fontSize: 14 }}>Text Formatting</Form.Label>
                        <div className="d-flex justify-content-between">
                            <InputGroup className="mb-3">
                                <InputGroup.Text
                                    variant={textProperties.bold ? "dark" : "light"}
                                    onClick={() => onTextPropertyChange('bold', !textProperties.bold)}
                                ><i className="fa-solid fa-bold"></i></InputGroup.Text>
                                <InputGroup.Text
                                    variant={textProperties.italic ? "dark" : "light"}
                                    onClick={() => onTextPropertyChange('italic', !textProperties.italic)}
                                ><i className="fa-solid fa-italic"></i></InputGroup.Text>
                                <InputGroup.Text
                                    variant={textProperties.underline ? "dark" : "light"}
                                    onClick={() => onTextPropertyChange('underline', !textProperties.underline)}
                                ><i className="fa-solid fa-underline"></i></InputGroup.Text>
                            </InputGroup>
                            <div>
                                <Form.Control
                                    type="color"
                                    value={textProperties.color}
                                    onChange={(e) => onTextPropertyChange('color', e.target.value)}
                                />
                            </div>
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className='fw-bold' style={{ fontSize: 14 }}>Alignment</Form.Label>
                        <div className="d-flex justify-content-between">
                            <InputGroup className="mb-3">
                                <InputGroup.Text
                                    style={{
                                        backgroundColor: textProperties.alignment === "left" ? "#103c5e" : "#103c5e00",
                                        color: textProperties.alignment === "left" ? "white" : "black",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => onTextPropertyChange('alignment', "left")}
                                >
                                    <i className="fa-solid fa-align-left" title='left'></i>
                                </InputGroup.Text>

                                <InputGroup.Text
                                    style={{
                                        backgroundColor: textProperties.alignment === "center" ? "#103c5e" : "#103c5e00",
                                        color: textProperties.alignment === "center" ? "white" : "black",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => onTextPropertyChange('alignment', "center")}
                                >
                                    <i className="fa-solid fa-align-center" title='center'></i>
                                </InputGroup.Text>

                                <InputGroup.Text
                                    style={{
                                        backgroundColor: textProperties.alignment === "right" ? "#103c5e" : "#103c5e00",
                                        color: textProperties.alignment === "right" ? "white" : "black",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => onTextPropertyChange('alignment', "right")}
                                >
                                    <i className="fa-solid fa-align-right" title='right'></i>
                                </InputGroup.Text>
                            </InputGroup>

                            <div>
                                <InputGroup.Text
                                    style={{
                                        backgroundColor: textProperties.rtl ? "#103c5e" : "#f8f9fa",
                                        color: textProperties.rtl ? "white" : "black",
                                        cursor: "pointer",
                                        padding: "2px 5px"
                                    }}
                                    onClick={() => onTextPropertyChange('rtl', !textProperties.rtl)}
                                >
                                    RTL
                                </InputGroup.Text>
                            </div>
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className='fw-bold' style={{ fontSize: 14 }}>Character Spacing</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="number"
                                value={textProperties.characterSpacing}
                                onChange={(e) => onTextPropertyChange('characterSpacing', parseInt(e.target.value))}
                            />
                            <InputGroup.Text>px</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>

                    {label && (
                        <Form.Group className="mb-3" controlId="labelBackgroundColor">
                            <Form.Label className='fw-bold' style={{ fontSize: 14 }}>Background Color</Form.Label>
                            <Form.Control
                                type="color"
                                onChange={handleColorChange}
                                defaultValue="#ffffff"
                                className='w-100'
                            />
                        </Form.Group>
                    )}
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default TextEditor;

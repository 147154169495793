import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Spinner, Table } from "react-bootstrap";
import { FaRegEye } from "react-icons/fa6";
import { loginActivitiesServices } from "../../../APIServices/loginActivitiesServices";

const LogTableData = ({ handleAddNewTab }) => {
  const [logData, setLogData] = useState([]);
  const [loader, setLoader] = useState(false);

  const getLogRecord = async () => {
    setLoader(true);
    let data = await loginActivitiesServices.getLoginHistoryRecord();
    let result = (data?.data || [])?.slice(0, 10);
    setLogData(result);
    setLoader(false);
  };

  useEffect(() => {
    getLogRecord();
  }, []);

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "login":
        return "green";
      case "logout":
        return "red";
      default:
        return "inherit";
    }
  };

  return (
    <>
      {loader ? (
        <div className="hstack justify-content-center h-50">
          <Spinner />
        </div>
      ) : logData?.length === 0 ? (
        <div className="hstack justify-content-center h-50">
          Data not found!
        </div>
      ) : (
        <>
          <Table className="text-nowrap mb-2" hover bordered>
            <thead>
              <tr>
                <th style={{ width: 40 }}>Sr.</th>
                <th>IP Address</th>
                <th>Time Zone</th>
                <th style={{ width: 100 }}>Status</th>
                <th style={{ width: 150 }}>IN Out Time</th>
              </tr>
            </thead>
            <tbody>
              {logData?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.ipAddress}</td>
                  <td>{item.timeZone}</td>
                  <td>
                    <div style={{ color: getStatusColor(item.status) }}>
                      <b className="text-capitalize">{item.status}</b>
                    </div>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm">
                      {item.createdAt}
                    </Moment>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {logData.length > 9 && (
            <div className="text-end">
              <button
                onClick={() =>
                  handleAddNewTab(
                    "Log History",
                    "fa-solid fa-history",
                    "historyId"
                  )
                }
                className={`p-1 px-3 border rounded-1 text-white bg-color fs-13`}
              >
                <FaRegEye style={{marginBottom: 2}}/> View All
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default LogTableData;

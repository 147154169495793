import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import { Card } from 'react-bootstrap';
import SidebarImageDesign from "./SidebarImageEditor/SidebarImageDesign";

const PdfEditorSidebar = ({ setShowName, setToggle, setShowText, setDateSigned, setCheckBox, setRadioChecks, toggle3,
  setToggle3, setNameTag, setEmailEdit, setJobTitle, setCompanyName, setEditTableDates, setLabel, setHyperlink }) => {

  const [activeCard, setActiveCard] = useState(null);

  const handleCardClick = (cardName, callback) => {
    setActiveCard(cardName);
    callback();
  };

  const DragItem = ({ type, icon, text, onClick }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: 'SIDEBAR_ITEM',
      item: { type },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));

    return (
      <Card
        ref={drag}
        style={{ height: 70, width: 105, opacity: isDragging ? 0.5 : 1 }}
        className={`mb-2 custom-cursor p-0 card_hover ${activeCard === type ? 'activee' : ''}`}
        onClick={onClick}
      >
        <Card.Body className="text-center p-2">
          <Card.Title><i className={icon}></i></Card.Title>
          <Card.Text className='fs-13'>{text}</Card.Text>
        </Card.Body>
      </Card>
    );
  };

  return (
    <div className='overflow-auto border-end' style={{ height: "calc(100vh - 115px)" }}>
      <div className='d-flex gap-2 flex-wrap py-4'>
        <DragItem
          type='signature'
          icon='fa-solid fa-pen-nib'
          text='Signature'
          onClick={() => handleCardClick('signature', () => setShowName(true))}
        />
        <DragItem
          type='initials'
          icon='fa-solid fa-wifi'
          text='Initials'
          onClick={() => handleCardClick('initials', () => setToggle(true))}
        />

        <DragItem
          type='textbox'
          icon='fa-brands fa-cc-discover'
          text='Textbox'
          onClick={() => {
            setDateSigned(false);
            setLabel(false);
            handleCardClick('textbox', () => setShowText(true))
          }}
        />

        <DragItem
          type='date signed'
          icon='fa-regular fa-calendar-minus'
          text='Date Signed'
          onClick={() => {
            setShowText(false);
            handleCardClick('date signed', () => setDateSigned(true))
          }}
        />

        <DragItem
          type='checkbox'
          icon='fa-regular fa-pen-to-square'
          text='Checkbox'
          onClick={() => {
            setRadioChecks(false);
            handleCardClick('checkbox', () => setCheckBox(true))
          }}
        />

        <DragItem
          type='radio'
          icon='fa-regular fa-circle-dot'
          text='Radio'
          onClick={() => {
            setCheckBox(false)
            handleCardClick('radio', () => setRadioChecks(true))
          }}
        />

        <DragItem
          type='name'
          icon='fa-regular fa-user'
          text='Name'
          onClick={() => handleCardClick('name', () => setNameTag(true))}
        />

        <DragItem
          type='email'
          icon='fa-regular fa-envelope'
          text='Email'
          onClick={() => handleCardClick('email', () => setEmailEdit(true))}
        />

        <DragItem
          type='title'
          icon='fa-solid fa-suitcase'
          text='Title'
          onClick={() => handleCardClick('title', () => setJobTitle(true))}
        />

        <DragItem
          type='company'
          icon='fa-regular fa-building'
          text='Company'
          onClick={() => handleCardClick('company', () => setCompanyName(true))}
        />

        <DragItem
          type='editable date'
          icon='fa-regular fa-calendar-plus'
          text='Editable Date'
          onClick={() => handleCardClick('editable date', () => setEditTableDates(true))}
        />

        <DragItem
          type='Image'
          icon='fa-regular fa-image'
          text='Image'
          onClick={() => handleCardClick('image', () => setToggle3(true))}
        />

        <DragItem
          type='label'
          icon='fa-solid fa-a'
          text='Label'
          onClick={() => {
            setShowText(false)
            handleCardClick('label', () => setLabel(true))
          }}
        />

        <DragItem
          type='hyperlink'
          icon='fa-solid fa-link'
          text='Hyperlink'
          onClick={() => handleCardClick('hyperlink', () => setHyperlink(true))}
        />

      </div>
      <SidebarImageDesign
        Show={!toggle3}
        Hide={setToggle3}
        Title={"Image Settings"}
        Submit={"Done"}
      />
    </div>
  )
}

export default PdfEditorSidebar

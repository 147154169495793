import React, { useState } from "react";
import { CiFilter } from "react-icons/ci";
import { AiOutlinePlus } from "react-icons/ai";
import { BiCollapse, BiExpand } from "react-icons/bi";
import TemplatesTableDesign from "./TemplatesTableDesign";

const TemplatesDesign = ({ handleAddNewTab }) => {
    const [isFullScreen, setIsFullScreen] = useState(false);

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };

    const HeaderTools = [
        { title: "Add New Templates", icon: <AiOutlinePlus />, onClick: () => handleAddNewTab("Add New Templates", "fa-solid fa-file", "addNewTemplatesId") },

        { title: "Filter", icon: <CiFilter /> },
    ];

    return (
        <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
            <div
                className="hstack justify-content-between w-100 border-bottom"
                style={{ padding: "4.6px 10px" }}
            >
                <div className="hstack gap-2">
                    {HeaderTools?.map((button, index) => (
                        <React.Fragment key={index}>
                            <button
                                title={button.title}
                                className="tabButtons border rounded-1 fs-13"
                                style={{ padding: "1px 5px" }}
                                onClick={button.onClick}
                            >
                                {button.icon}
                            </button>
                            {index < 3 && <div className="vr"></div>}
                        </React.Fragment>
                    ))}
                </div>
                <button
                    className="tabButtons border rounded-1 fs-13"
                    title={!isFullScreen ? "Full Mode" : "Original Mode"}
                    onClick={toggleFullScreen}
                >
                    {isFullScreen ? <BiCollapse /> : <BiExpand />}
                </button>
            </div>
            <div className="p-2 pt-0">
                <TemplatesTableDesign />
            </div>
        </div>
    );
};

export default TemplatesDesign;


import { useState, useEffect } from "react";

const TimeDuration = ({ DateTime }) => {
  const [duration, setDuration] = useState("");
  const calculateDuration = (DateTime) => {
    const givenDate = new Date(DateTime);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate - givenDate);
    const diffSeconds = Math.floor(diffTime / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);
    const diffMonths = Math.floor(diffDays / 30);
    const diffYears = Math.floor(diffMonths / 12);
    if (diffYears > 0) {
      setDuration(`${diffYears} year${diffYears > 1 ? "s" : ""} ago`);
    } else if (diffMonths > 0) {
      setDuration(`${diffMonths} month${diffMonths > 1 ? "s" : ""} ago`);
    } else if (diffDays > 0) {
      setDuration(`${diffDays} day${diffDays > 1 ? "s" : ""} ago`);
    } else if (diffHours > 0) {
      setDuration(`${diffHours} hour${diffHours > 1 ? "s" : ""} ago`);
    } else if (diffMinutes > 0) {
      setDuration(`${diffMinutes} minute${diffMinutes > 1 ? "s" : ""} ago`);
    } else {
      setDuration(`${diffSeconds} second${diffSeconds > 1 ? "s" : ""} ago`);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
        calculateDuration(DateTime);
    }, 1000);

    return () => clearInterval(interval);
  }, [DateTime]);

  return duration;
};

export default TimeDuration;
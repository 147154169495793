import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const SignatureDraw = () => {
  const sigCanvas = useRef(null);

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <SignatureCanvas
        ref={sigCanvas}
        penColor="black"
        canvasProps={{ width: 560, height: 200, style: { border: '1px solid green', marginBottom: '10px', marginTop: "10px" } }}
      />
      <button
        onClick={clearSignature}
        className="p-1 px-4 border rounded-1 text-white bg-color fs-13"
      >
        Clear
      </button>
      <div className='fs-13 mt-2 mb-2'>
        <b>Note:</b> I understand that this is the legal representation of my signature
      </div>
    </div>
  );
};

export default SignatureDraw;

import React, { useState } from "react";
import { TbLogs } from "react-icons/tb";
import LogTableData from "./LogTableData";
import FailedStatus from "./FailedStatus";
import RecentActivity from "./RecentActivity";
import { GrStatusWarning } from "react-icons/gr";
import { MdOutlineManageHistory } from "react-icons/md";

const ProfileActivity = ({ handleAddNewTab }) => {
  const [activeTab, setActiveTab] = useState("RecentActivity");

  const renderActiveTab = () => {
    switch (activeTab) {
      case "RecentActivity":
        return <RecentActivity handleAddNewTab={handleAddNewTab}/>;
      case "LogHistory":
        return <LogTableData handleAddNewTab={handleAddNewTab} />;
      case "FailedStatus":
        return <FailedStatus handleAddNewTab={handleAddNewTab} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="hstack gap-2 mb-2">
        <button
          className={`w-100 hstack gap-1 justify-content-center tabButtons border rounded-1 fs-13 p-1 ${
            activeTab === "RecentActivity" ? "active" : ""
          }`}
          onClick={() => setActiveTab("RecentActivity")}
        >
          <MdOutlineManageHistory />
          <div>Recent Activity</div>
        </button>
        <button
          className={`w-100 hstack gap-1 justify-content-center tabButtons border rounded-1 fs-13 p-1 ${
            activeTab === "LogHistory" ? "active" : ""
          }`}
          onClick={() => setActiveTab("LogHistory")}
        >
          <TbLogs />
          <div>Log History</div>
        </button>
        <button
          className={`w-100 hstack gap-1 justify-content-center tabButtons border rounded-1 fs-13 p-1 ${
            activeTab === "FailedStatus" ? "active" : ""
          }`}
          onClick={() => setActiveTab("FailedStatus")}
        >
          <GrStatusWarning />
          <div>Failed Status</div>
        </button>
      </div>
      {renderActiveTab()}
    </>
  );
};

export default ProfileActivity;

import React, { useState } from "react";
import {
  FaBookmark,
  FaEdit,
  FaEnvelope,
  FaReply,
  FaSignInAlt,
  FaSignOutAlt,
  FaSignature,
  FaUpload,
  FaUserCheck,
  FaUserTimes,
} from "react-icons/fa";
import {
  FaCircleCheck,
  FaCirclePlus,
  FaCircleXmark,
  FaTrashCan,
} from "react-icons/fa6";
import Moment from "react-moment";
import { CiExport } from "react-icons/ci";
import { Spinner, Table } from "react-bootstrap";
import Pagination from "../../Hooks/Pagination";

const RecentActivities = () => {
  const [loader, setLoader] = useState(false);

  const activities = [
    {
      date: "Sep 25",
      action: "Update",
      text: "Responded to need",
      linkText: "“Volunteer opportunity”",
      linkHref: "#",
    },
    {
      date: "Sep 24",
      text: "Added an interest “Volunteer Activities”",
      action: "Add",
    },
    {
      date: "Sep 23",
      text: "Joined the group",
      linkText: "“Boardsmanship Forum”",
      action: "Join",
      linkHref: "#",
    },
    {
      date: "Sep 21",
      text: "Responded to need",
      linkText: "“In-Kind Opportunity”",
      action: "Reply",
      linkHref: "#",
    },
    {
      date: "Sep 18",
      text: "Created need",
      linkText: "“Volunteer Opportunity”",
      action: "Add",
      linkHref: "#",
    },
    {
      date: "Sep 17",
      text: "Attending the event",
      linkText: "“Some New Event”",
      action: "Add",
      linkHref: "#",
    },
  ];
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(17);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = activities?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(activities?.length / itemsPerPage);

  return (
    <>
      {loader ? (
        <div className="hstack justify-content-center h-75">
          <Spinner />
        </div>
      ) : activities?.length === 0 ? (
        <div className="hstack justify-content-center h-75">
          Data not found!
        </div>
      ) : (
        <>
          <div
            className="border-bottom text-end"
            style={{ padding: "3.2px 10px" }}
          >
            <button
              title="Export"
              className="tabButtons border rounded-1 fs-13"
              style={{ padding: "1px 5px" }}
            >
              <CiExport />
            </button>
          </div>
          <div className="mt-2 px-2 ">
            <div
              className="overflow-auto"
              style={{ maxHeight: "calc(100vh - 200px)" }}
            >
              <Table className="text-nowrap mb-2" hover bordered>
                <thead className="position-sticky" style={{ top: -1 }}>
                  <tr>
                    <th style={{ width: 40 }}>Sr.</th>
                    <th className="text-center" style={{ width: 40 }}>Status</th>
                    <th className="text-center">Type</th>
                    <th>Activity By</th>
                    <th>Activities</th>
                    <th>Category</th>
                    <th style={{ width: 40 }}>Activity Date</th>
                    <th style={{ width: 40 }}>Activity Time</th>
                  </tr>
                </thead>
                <tbody>
                  {result?.map((item, index) => (
                    <tr className="relative">
                      <td>{index + 1}.</td>
                      <td
                        title={item.status === "200" ? "Success" : "Failed"}
                        className="text-center"
                      >
                        {item.status === "200" ? (
                          <FaCircleCheck className="text-success" />
                        ) : (
                          <FaCircleXmark className="text-danger" />
                        )}
                      </td>
                      <td
                        title={
                          item.type === "insert"
                            ? "Add Data"
                            : item.type === "update"
                            ? "Update Data"
                            : item.type === "delete"
                            ? "Delete Data"
                            : item.type === "assign"
                            ? "Assign Data"
                            : item.type === "unassign"
                            ? "Unassign Data"
                            : item.type === "upload"
                            ? "Upload Document"
                            : item.type === "bookmark"
                            ? "Bookmark Document"
                            : item.type === "mail"
                            ? "Document Mail"
                            : item.type === "reply"
                            ? "Reply"
                            : item.type === "completed"
                            ? "Completed"
                            : item.type === "signature"
                            ? "Signature"
                            : item.type === "checkin"
                            ? "CheckIn"
                            : item.type === "checkout"
                            ? "Checkout"
                            : ""
                        }
                        className="text-center"
                      >
                        {item.type === "insert" ? (
                          <FaCirclePlus />
                        ) : item.type === "update" ? (
                          <FaEdit />
                        ) : item.type === "delete" ? (
                          <FaTrashCan />
                        ) : item.type === "assign" ? (
                          <FaUserCheck />
                        ) : item.type === "unassign" ? (
                          <FaUserTimes />
                        ) : item.type === "upload" ? (
                          <FaUpload />
                        ) : item.type === "bookmark" ? (
                          <FaBookmark />
                        ) : item.type === "reply" ? (
                          <FaReply />
                        ) : item.type === "completed" ? (
                          <FaCircleCheck />
                        ) : item.type === "signature" ? (
                          <FaSignature />
                        ) : item.type === "mail" ? (
                          <FaEnvelope />
                        ) : item.type === "checkin" ? (
                          <FaSignInAlt />
                        ) : item.type === "checkout" ? (
                          <FaSignOutAlt />
                        ) : (
                          ""
                        )}
                      </td>
                      <td title={item.activityById}>{item.activityBy}</td>
                      <td>{item.activities}</td>
                      <td className="text-capitalize">
                        {item.module === "workflowTask"
                          ? "Workflow Task"
                          : item.module}
                      </td>
                      <td>
                        <Moment format="DD MMM YYYY">{item?.createdAt}</Moment>
                      </td>
                      <td>
                        <Moment format="HH:mm:ss">{item?.createdAt}</Moment>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <Pagination
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalPage={totalPage}
              totalItems={activities?.length}
            />
          </div>
        </>
      )}
    </>
  );
};

export default RecentActivities;

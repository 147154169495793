import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { TfiBackLeft } from "react-icons/tfi";
import { Alert, Card, Form, Spinner } from "react-bootstrap";
import { authServices } from "../../APIServices/authServices";

const ForgetPassword = () => {
  const [email, setEmail] = useState();
  const [loader, setLoader] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  const forgotPassword = async () => {
    setLoader(true);
    let userData = {};
    userData.email = email;
    let data = await authServices.forgotPassword(userData);
    setLoader(false);
    setStatusMessage(data);
    if (data?.statusCode === 200) {
      window.location.href = "/login";
    }
    setTimeout(() => {
      setStatusMessage("");
    }, 3000);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(email)) {
      setEmailError("");
    } else {
      setEmailError("Invalid Email, Please Enter Valid Email!");
    }
  };
  return (
    <>
      <div
        className="w-100 hstack justify-content-center"
        style={{ height: "100vh" }}
      >
        <Card
          style={{ width: "30rem" }}
          className="border rounded-1 shadow-sm p-3"
        >
          <Card.Body>
            <Card.Title className="fw-bold text-center">
              Forget Password
            </Card.Title>
            <Card.Text>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1">
                  Email <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setEmail(e.target.value);
                    validateEmail(e.target.value);
                  }}
                  className={
                    (emailError && "border-danger text-danger") ||
                    (email && "border-success")
                  }
                  value={email}
                  required
                  type="email"
                  placeholder="Enter Email ID"
                />
                {emailError && email && (
                  <Form.Text className="fs-10 m-0 text-danger">
                    {emailError}
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group className="mb-2">
                <button
                  className={`w-100 hstack gap-1 justify-content-center tabButtons border rounded-1 fs-13 p-1`}
                  disabled={loader || !email || emailError}
                  validated={validateEmail}
                  onClick={forgotPassword}
                >
                  <div className="fw-bold">
                    {loader && <Spinner style={{ width: 14, height: 14 }} />}{" "}
                    Forget Password
                  </div>
                </button>
              </Form.Group>
              <Form.Group className="mb-2 text-end">
                <NavLink to={"/login"} className="fs-13 text-dark" title="Click for Login panel">
                  Back to Login <TfiBackLeft/>
                </NavLink>
              </Form.Group>
              {statusMessage && (
                <Alert
                  className="fs-10 py-1 px-1 m-0"
                  variant={
                    statusMessage?.statusCode === 200 ? "success" : "danger"
                  }
                >
                  {statusMessage?.customMessage}
                </Alert>
              )}
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default ForgetPassword;

import React, { useState } from 'react'
import { Card } from 'react-bootstrap'

const SinatureUpload = () => {

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <div>
      <Card style={{ padding: '10px', backgroundColor: "#e4e9ed00" }} className="mb-3">
        <Card.Body className="text-center">
          <Card.Title>Upload Signature</Card.Title>
          <Card.Text>
            {selectedFile ? selectedFile.name : 'No file selected'}
          </Card.Text>
          <input
            type="file"
            style={{ display: 'none' }}
            id="file-upload"
            onChange={handleFileChange}
          />
          <button className="p-2 px-5 border rounded-1 text-white bg-color fs-13" onClick={() => document.getElementById('file-upload').click()}>
            Choose File
          </button>
        </Card.Body>
      </Card>
    </div>
  )
}

export default SinatureUpload

import { Col, Container, Row } from "react-bootstrap";
import "./App.css";
import Header from "./Component/Header";
import Footer from "./Component/Footer";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Login,
  SignUp,
  ControlPanel,
  ResetPassword,
  ChangePassword,
  ForgetPassword,
  OtpVerification,
} from "./Component";
import { authServices } from "./APIServices/authServices";
import { Permission } from "./ProtectRoute/PermissionRoutes";

function App() {
  const { LoginPermission, DashboardPermission } = Permission;

  const [twoFA, setTwoFA] = useState(false);
  const [passwordUpdate, setPasswordUpdate] = useState(false);
  const [email, setEmail] = useState("");
  const [authType, setAuthType] = useState("");
  const [userCurrentData, setUserCurrentData] = useState("");

  let currentUserData = async () => {
    let result = await authServices.getCurrectLoginUser();
    let data = result?.currentData;
    setEmail(data?.email);
    setTwoFA(data?.twoFA);
    setPasswordUpdate(data?.isUpdatePassword);
    setAuthType(data?.authType);
    setUserCurrentData(data);
  };

  useEffect(() => {
    currentUserData();
  }, []);

  const [tabs, setTabs] = useState([]);

  const handleAddNewTab = (tabName, icon, tabId) => {
    let newTab = {
      tabId: tabId,
      tabName: tabName,
      icon: icon,
      openDT: new Date(),
      status: "active",
    };
    const updatedTabs = tabs.map((tab) => ({ ...tab, status: "inactive" }));
    const existTab = tabs.some((tab) => tab.tabId === tabId);
    if (existTab) {
      updatedTabs.forEach((element) => {
        if (element.tabId === tabId) {
          element.status = "active";
        }
      });
      setTabs(updatedTabs || []);
    } else {
      setTabs([...updatedTabs, newTab] || []);
    }
  };

  useEffect(() => {
    const savedTabs = JSON.parse(localStorage.getItem("tabs"));
    if (savedTabs) {
      setTabs(savedTabs || []);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("tabs", JSON.stringify(tabs));
  }, [tabs]);

  return (
    <Container fluid>
      <Row>
        <Col className="p-0">
          <div id="App">
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<LoginPermission />}>
                  <Route path="/" element={<Login />} />
                </Route>
                <Route path="/login" element={<LoginPermission />}>
                  <Route path="/login" element={<Login />} />
                </Route>
                <Route path="/signup" element={<LoginPermission />}>
                  <Route path="/signup" element={<SignUp />} />
                </Route>
                <Route path="/forget-password" element={<LoginPermission />}>
                  <Route path="/forget-password" element={<ForgetPassword />} />
                </Route>
                <Route path="/otp-verification" element={<LoginPermission />}>
                  <Route
                    path="/otp-verification"
                    element={
                      <OtpVerification
                        authType={authType}
                        currentUserData={currentUserData}
                      />
                    }
                  />
                </Route>
                <Route path="/reset-password" element={<LoginPermission />}>
                  <Route path="/reset-password" element={<ResetPassword />} />
                </Route>
                <Route path="/change-password" element={<LoginPermission />}>
                  <Route
                    path="/change-password"
                    element={
                      <ChangePassword currentUserData={currentUserData} />
                    }
                  />
                </Route>
                <Route
                  path="*"
                  element={
                    <>
                      <Header
                        handleAddNewTab={handleAddNewTab}
                        userCurrentData={userCurrentData}
                      />
                      <Routes>
                        <Route
                          path="/home"
                          element={
                            <DashboardPermission
                              twoFA={twoFA}
                              authType={authType}
                              email={email}
                              passwordUpdate={passwordUpdate}
                              currentUserData={currentUserData}
                            />
                          }
                        >
                          <Route
                            path="/home"
                            element={
                              <ControlPanel
                                setTabs={setTabs}
                                tabs={tabs}
                                userCurrentData={userCurrentData}
                                currentUserData={currentUserData}
                                handleAddNewTab={handleAddNewTab}
                              />
                            }
                          />
                        </Route>
                      </Routes>
                      <Footer />
                    </>
                  }
                />
              </Routes>
            </BrowserRouter>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default App;

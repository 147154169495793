import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import ProfileActivity from "./ProfileActivity";
import { authServices } from "../../../APIServices/authServices";
import { addressServices } from "../../../APIServices/addressServices";
import { Alert, Col, Container, Form, Row, Spinner } from "react-bootstrap";

const UserDetails = ({ handleAddNewTab, userCurrentData, currentUserData }) => {
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [state, setState] = useState("");
  const [image, setImage] = useState(null);
  const [gender, setGender] = useState("");
  const [cityId, setCityId] = useState("");
  const [stateId, setStateId] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [address, setAddress] = useState("");
  const [loader, setLoader] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [lastName, setLastName] = useState("");
  const [countryId, setCountryId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [stateList, setStateList] = useState([]);
  const [middleName, setMiddleName] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");

  const updateProfile = async () => {
    setLoader(true);
    let userData = {};
    userData.firstName = firstName;
    userData.middleName = middleName;
    userData.lastName = lastName;
    userData.phone = phone;
    userData.gender = gender;
    userData.address = address;
    userData.countryId = countryId;
    userData.stateId = stateId;
    userData.cityId = cityId;
    userData.zipCode = zipCode;
    let data = await authServices.updateProfile(userData);
    setStatusMessage(data);
    if (data?.statusCode) {
      currentUserData();
      setLoader(false);
    }
    setTimeout(() => {
      setStatusMessage("");
    }, 3000);
  };

  const getCountries = async () => {
    let data = await addressServices.getCountries();
    setCountryList(data?.data);
  };

  const getStates = async (id) => {
    let data = await addressServices.getStates(id);
    setStateList(data?.data);
    setStateId("");
    setState("");
    setCityId("");
    setCity("");
  };

  const getCities = async (e) => {
    let data = await addressServices.getCities(
      e.target[e.target.selectedIndex].title
    );
    setCityList(data?.data);
  };

  const findStateName = async (countryId) => {
    let data = await addressServices.findStateName(countryId);
    setState(data?.data);
  };

  const findCityName = async (stateId) => {
    let data = await addressServices.findCityName(stateId);
    setCity(data?.data);
  };

  useEffect(() => {
    findStateName(userCurrentData?.stateId);
    findCityName(userCurrentData?.cityId);
    setFirstName(userCurrentData?.firstName);
    setMiddleName(userCurrentData?.middleName);
    setLastName(userCurrentData?.lastName);
    setPhone(userCurrentData?.phone);
    setGender(userCurrentData?.gender);
    setAddress(userCurrentData?.address);
    setCountryId(userCurrentData?.countryId);
    setStateId(userCurrentData?.stateId);
    setCityId(userCurrentData?.cityId);
    setZipCode(userCurrentData?.zipCode);
    setImage(userCurrentData?.profileImage);
    getCountries();
  }, [userCurrentData]);

  const handleImageChange = async (e) => {
    let fileData = e.target.files[0];
    if (fileData) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(fileData);

      const formData = new FormData();
      formData.append("file", fileData);

      let result = await authServices.updateProfileImage(
        formData,
        userCurrentData?._id
      );
      if (result?.statusCode === 200) {
        currentUserData();
      }
    }
  };

  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;

    if (phonePattern.test(phone)) {
      setPhoneError("");
    } else {
      setPhoneError("Phone Number should have 10 digits!");
    }
  };

  const imageSrc = useState(
    "https://www.imgacademy.com/sites/default/files/img-academy-boarding-school-worlds-most-dedicated.jpg"
  );

  return (
    <>
      <Container fluid>
        <Row>
          <Form.Group
            as={Col}
            md={7}
            className="border-end overflow-auto"
            style={{ height: "calc(100vh - 116px)" }}
          >
            <div className="p-2 px-3 rounded-1 border my-2">
              <Form.Label className="fw-bold">Basic Information</Form.Label>
              <Row>
                <Form.Group as={Col} md={6} className="mb-2">
                  <Form.Label className="mb-1">
                    First Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    className={firstName && "border-success"}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} md={6} className="mb-2">
                  <Form.Label className="mb-1">Middle Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Middle Name"
                    value={middleName}
                    className={middleName && "border-success"}
                    onChange={(e) => setMiddleName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} md={6} className="mb-2">
                  <Form.Label className="mb-1">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    value={lastName}
                    className={lastName && "border-success"}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} md={6} className="mb-2">
                  <Form.Label className="mb-1">
                    Email Id <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    disabled
                    type="email"
                    placeholder="Enter Email ID"
                    value={userCurrentData?.email}
                  />
                </Form.Group>
                <Form.Group as={Col} md={6} className="mb-2">
                  <Form.Label className="mb-1">
                    Phone No. <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Phone No."
                    className={
                      (phoneError && "border-danger text-danger") ||
                      (phone && "border-success")
                    }
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                      validatePhoneNumber(e.target.value);
                    }}
                    required
                  />
                  {phoneError && phone && (
                    <Form.Text className="fs-10 m-0 text-danger">
                      {phoneError}
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group as={Col} md={6} className="mb-2">
                  <Form.Label className="mb-1">
                    Gender <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    onChange={(e) => setGender(e.target.value)}
                    className={gender && "border-success"}
                  >
                    <option>Select Gender</option>
                    <option selected={gender === "male"} value="male">
                      Male
                    </option>
                    <option selected={gender === "female"} value="female">
                      Female
                    </option>
                    <option selected={gender === "other"} alue="other">
                      Other
                    </option>
                  </Form.Select>
                </Form.Group>
              </Row>
              <Form.Label className="fw-bold mt-3">
                Address Information
              </Form.Label>
              <Row>
                <Form.Group as={Col} md={12} className="mb-2">
                  <Form.Label className="mb-1">
                    Address <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    className={address && "border-success"}
                    placeholder="Enter Address"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} md={6} className="mb-2">
                  <Form.Label className="mb-1">
                    Country <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    onChange={(e) => setCountryId(e.target.value)}
                    onClick={(e) =>
                      getStates(e.target[e.target.selectedIndex].title)
                    }
                    className={address && "border-success"}
                  >
                    <option value="">Select Country</option>
                    {countryList?.length > 0
                      ? countryList?.map((item) => (
                          <option
                            selected={item._id === countryId}
                            value={item._id}
                            title={item.id}
                          >
                            {item.name}
                          </option>
                        ))
                      : ""}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} md={6} className="mb-2">
                  <Form.Label className="mb-1">
                    State <span className="text-danger">*</span>
                  </Form.Label>
                  {stateList?.length > 0 ? (
                    <Form.Select
                      onChange={(e) => setStateId(e.target.value)}
                      onClick={(e) => getCities(e)}
                      className={address && "border-success"}
                    >
                      <option value="">Select State</option>
                      {stateList?.map((item) => (
                        <option
                          selected={item._id === stateId}
                          value={item._id}
                          title={item.id}
                          id={item.gst_stateCode}
                        >
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                  ) : (
                    <Form.Control required type="text" disabled value={state} />
                  )}
                </Form.Group>
                <Form.Group as={Col} md={6} className="mb-2">
                  <Form.Label className="mb-1">
                    City <span className="text-danger">*</span>
                  </Form.Label>
                  {cityList?.length > 0 ? (
                    <Form.Select
                      onChange={(e) => setCityId(e.target.value)}
                      className={address && "border-success"}
                    >
                      <option value="">Select City</option>
                      {cityList?.map((item) => (
                        <option selected={item._id === cityId} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                  ) : (
                    <Form.Control required type="text" disabled value={city} />
                  )}
                </Form.Group>
                <Form.Group as={Col} md={6} className="mb-2">
                  <Form.Label className="mb-1">
                    Postal Code <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    className={zipCode && "border-success"}
                    placeholder="Enter Postal Code"
                    onChange={(e) => setZipCode(e.target.value)}
                    value={zipCode}
                  />
                </Form.Group>
                <Form.Group as={Col} md={12} className="my-1 pt-2 text-end">
                  <button
                    disabled={
                      phoneError ||
                      !phone ||
                      !firstName ||
                      !address ||
                      !countryId ||
                      !stateId ||
                      !cityId ||
                      !zipCode
                    }
                    className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
                    onClick={updateProfile}
                  >
                    <div className="fw-bold">
                      {loader ? (
                        <Spinner style={{ width: 14, height: 14 }} />
                      ) : (
                        <FiEdit style={{ marginBottom: 3 }} />
                      )}{" "}
                      Update
                    </div>
                  </button>
                </Form.Group>
                {statusMessage && (
                  <Form.Group className="my-2">
                    <Alert
                      className="fs-10 py-1 px-1 m-0 text-center"
                      variant={
                        statusMessage?.statusCode === 200 ? "success" : "danger"
                      }
                    >
                      {statusMessage?.customMessage}
                    </Alert>
                  </Form.Group>
                )}
              </Row>
            </div>
          </Form.Group>
          <Form.Group
            as={Col}
            md={5}
            className="overflow-auto"
            style={{ height: "calc(100vh - 116px)" }}
          >
            <div className="rounded-1 border overflow-hidden my-2">
              <img
                src="https://i.pinimg.com/originals/42/84/71/428471d4f6c147b88ee7c2cff3efc4cb.jpg"
                className="w-100"
                style={{ height: 100 }}
                alt="Cover"
              />
              <center className="p-2">
                <div className="profile-pic mb-2 hstack position-relative justify-content-center">
                  <label
                    className="-label hstack justify-content-center text-white mb-0 rounded-circle"
                    htmlFor="imageUpload"
                  >
                    <span>Change Image</span>
                  </label>
                  <Form.Control
                    id="imageUpload"
                    type="file"
                    onChange={handleImageChange}
                    className="d-none"
                    accept=".png, .jpg, .jpeg"
                  />
                  {image ? (
                    <img
                      src={image}
                      className="rounded-circle shadow border border-secondary position-absolute z-0"
                      alt="Profile"
                    />
                  ) : (
                    <img
                      src={imageSrc}
                      className="rounded-circle shadow border border-secondary position-absolute z-0"
                      alt="Profile"
                    />
                  )}
                </div>
                <div className="fs-13">{userCurrentData?.fullName}</div>
                <div className="fs-13">{userCurrentData?.email}</div>
                <div className="fs-13">{userCurrentData?.address}</div>
              </center>
            </div>
            <ProfileActivity handleAddNewTab={handleAddNewTab} />
          </Form.Group>
        </Row>
      </Container>
    </>
  );
};

export default UserDetails;

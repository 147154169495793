import React, { useState } from "react";
import { Card, Collapse, Dropdown, Form } from "react-bootstrap";

const AddRecipient = () => {
    const [openPanel, setOpenPanel] = useState("");
    const [designs, setDesigns] = useState([{ id: 0 }]);
    const [selectedSett, setSelectedSett] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [privateMessage, setPrivateMessage] = useState(false);
    const [selectedAuthMethod, setSelectedAuthMethod] = useState({});
    const [authenticationEnabled, setAuthenticationEnabled] = useState({});

    const handleSelect = (e) => {
        setSelectedOption(e.target.labels[0].textContent);
    };

    const handleSelectSett = (e) => {
        setSelectedSett(e.target.labels[0].textContent);
    };

    const addDesign = () => {
        setDesigns([...designs, { id: designs.length }]);
    };
 
    const deleteDesign = (id) => {
        setDesigns(designs.filter(design => design.id !== id));
    };

    const handleAuthSwitch = (id) => {
        setAuthenticationEnabled({
            ...authenticationEnabled,
            [id]: !authenticationEnabled[id],
        });
    };

    const handleAuthMethodChange = (id, method) => {
        setSelectedAuthMethod({
            ...selectedAuthMethod,
            [id]: method,
        });
    };

    const toggleSwitch = () => {
        setPrivateMessage(!privateMessage);
    };

    return (
        <>
            <Card className="mb-3">
                <Card.Body>
                    <div className="d-flex justify-content-between">
                        <div>
                            <Card.Title style={{ fontSize: 17 }}>Add Recipients</Card.Title>
                        </div>
                        <div className="d-flex gap-2">
                            <div style={{ color: "blue" }} className="cursor-pointer">
                                Add me
                            </div>|
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Signing order"
                            />
                            <div>
                                <i className="fa-regular fa-circle-question" title="Enable this option to specify signing order for recipients."></i>
                            </div>
                        </div>
                    </div>
                    {designs?.map((design, index) => (
                        <div key={design.id}>
                            <div className="d-flex gap-2">
                                <Form.Group className="mb-3 w-100" controlId={`exampleForm.ControlInput1-${design.id}`}>
                                    <Form.Control type="text" placeholder="Recipient name*" />
                                </Form.Group>
                                <Form.Group className="mb-3 w-100" controlId={`exampleForm.ControlInput2-${design.id}`}>
                                    <Form.Control type="email" placeholder="Recipient email*" />
                                </Form.Group>
                                <Dropdown>
                                    <Dropdown.Toggle className="border rounded-1 text-white bg-color" style={{ padding: "3px 10px" }}>
                                        <i className="fa-solid fa-pen-nib"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="Signer">Signer</Dropdown.Item>
                                        <Dropdown.Item href="Reviewer">Reviewer</Dropdown.Item>
                                        <Dropdown.Item href="in-person">In person signer</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                {index !== 0 && (
                                    <div className="py-1">
                                        <i className="fa-regular fa-trash-can text-danger" title="Delete" onClick={() => deleteDesign(design.id)}></i>
                                    </div>
                                )}
                            </div>
                            <div className="d-flex gap-2 mb-1">
                                <div>
                                    <button
                                        onClick={() => setOpenPanel(openPanel === `delivery-${design.id}` ? null : `delivery-${design.id}`)}
                                        aria-controls={`example-collapse-text-${design.id}`}
                                        aria-expanded={openPanel === `delivery-${design.id}`}
                                        className="border rounded-1 text-white bg-color mb-1"
                                    >
                                        <i className="fa-solid fa-location-arrow"></i> {selectedOption || 'Email'}
                                    </button>
                                    <Collapse in={openPanel === `delivery-${design.id}`}>
                                        <div id={`example-collapse-text-${design.id}`}>
                                            Delivery options
                                            {['radio'].map((type) => (
                                                <div key={`default-${type}-${design.id}`} className="mb-3">
                                                    <Form.Check
                                                        label="Email"
                                                        name={`group1-${design.id}`}
                                                        type={type}
                                                        id={`default-${type}-1-${design.id}`}
                                                        onChange={handleSelect}
                                                    />
                                                    <Form.Check
                                                        label="SMS"
                                                        name={`group1-${design.id}`}
                                                        type={type}
                                                        id={`default-${type}-2-${design.id}`}
                                                        onChange={handleSelect}
                                                    />
                                                    <Form.Check
                                                        label="Email & SMS"
                                                        name={`group1-${design.id}`}
                                                        type={type}
                                                        id={`default-${type}-3-${design.id}`}
                                                        onChange={handleSelect}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </Collapse>
                                </div>
                                <div>
                                    <button
                                        onClick={() => setOpenPanel(openPanel === `settings-${design.id}` ? null : `settings-${design.id}`)}
                                        aria-controls={`example-collapse-text-${design.id}`}
                                        aria-expanded={openPanel === `settings-${design.id}`}
                                        className="border rounded-1 text-white bg-color mb-1"
                                    >
                                        <i className="fa-solid fa-location-arrow"></i> {selectedSett || 'Authentication'}
                                    </button>
                                    <Collapse in={openPanel === `settings-${design.id}`}>
                                        <div id={`example-collapse-text-${design.id}`}>
                                            Delivery options
                                            {['radio'].map((type) => (
                                                <div key={`reverse-${type}-${design.id}`} className="mb-3">
                                                    <div className="d-flex gap-2">
                                                        <Form.Check
                                                            label="Authentication"
                                                            name={`group2-${design.id}`}
                                                            type={type}
                                                            id={`reverse-${type}-1-${design.id}`}
                                                            onChange={handleSelectSett}
                                                        />
                                                        <Form.Check
                                                            type="switch"
                                                            id={`custom-switch-${design.id}`}
                                                            onChange={() => handleAuthSwitch(design.id)}
                                                        />
                                                    </div>
                                                    {authenticationEnabled[design.id] && (
                                                        <>
                                                            <div className="ms-3">
                                                                <Form.Check
                                                                    type="radio"
                                                                    label="Email OTP (One Time Password)"
                                                                    name={`auth-method-${design.id}`}
                                                                    id={`auth-method-email-otp-${design.id}`}
                                                                    onChange={() => handleAuthMethodChange(design.id, "Email OTP")}
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    label="SMS OTP (upgrade)"
                                                                    name={`auth-method-${design.id}`}
                                                                    id={`auth-method-sms-otp-${design.id}`}
                                                                    onChange={() => handleAuthMethodChange(design.id, "SMS OTP")}
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    label="Access Code"
                                                                    name={`auth-method-${design.id}`}
                                                                    id={`auth-method-access-code-${design.id}`}
                                                                    onChange={() => handleAuthMethodChange(design.id, "Access Code")}
                                                                />
                                                                {selectedAuthMethod[design.id] === "Access Code" && (
                                                                    <Form.Group className="mt-2" controlId={`access-code-textarea-${design.id}`}>
                                                                        <Form.Control type="text" placeholder="Enter access code" />
                                                                    </Form.Group>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className="d-flex gap-2">
                                                        <Form.Check
                                                            label="Private message"
                                                            name={`group2-${design.id}`}
                                                            type={type}
                                                            id={`reverse-${type}-2-${design.id}`}
                                                            onChange={handleSelectSett}
                                                        />
                                                        <Form.Check
                                                            type="switch"
                                                            id={`custom-switch-${design.id}`}
                                                            onChange={toggleSwitch}
                                                        />
                                                    </div>
                                                    {privateMessage && (
                                                        <Form.Group className="mt-1 mb-1 ms-3 w-100">
                                                            <Form.Control as="textarea" rows={3} placeholder="Enter private message to recipient" />
                                                        </Form.Group>
                                                    )}
                                                    <div className="d-flex gap-2">
                                                        <Form.Check
                                                            label="Allow Field Configuration"
                                                            name={`group2-${design.id}`}
                                                            type={type}
                                                            id={`reverse-${type}-3-${design.id}`}
                                                            onChange={handleSelectSett}
                                                        />
                                                        <Form.Check
                                                            type="switch"
                                                            id={`custom-switch-${design.id}`}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    ))}
                    <button onClick={addDesign} className="p-2 px-5 border rounded-1 text-color bg-#e4e9ed00 w-100 mt-2" style={{ fontSize: 15 }}>
                        <i className="fa-solid fa-plus me-1 fs-13"></i>Add Recipient
                    </button>
                </Card.Body>
            </Card>
        </>
    );
};

export default AddRecipient;

import React, { useState } from "react";
import AddRecipient from "./AddRecipient";
import { Card, Container, Form } from "react-bootstrap";

const AddNewDocument = ({ handleAddNewTab }) => {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    return (
        <div className="overflow-auto" style={{ height: "calc(100vh - 115px)" }}>
            <Container fluid className="my-3">
                <Card style={{ padding: '10px', backgroundColor: "#e4e9ed00" }} className="mb-3">
                    <Card.Body className="text-center">
                        <Card.Title>Upload Document</Card.Title>
                        <Card.Text>
                            {selectedFile ? selectedFile.name : 'No file selected'}
                        </Card.Text>
                        <input
                            type="file"
                            style={{ display: 'none' }}
                            id="file-upload"
                            onChange={handleFileChange}
                        />
                        <button className="p-2 px-5 border rounded-1 text-white bg-color fs-13" onClick={() => document.getElementById('file-upload').click()}>
                            Choose File
                        </button>
                    </Card.Body>
                </Card>
                <AddRecipient />
                <Card style={{ padding: '10px' }} className="mb-3">
                    <Card.Body >
                        <Card.Title style={{ fontSize: 17 }}>Add CC</Card.Title>
                        <Form.Group className="mb-2">
                            <Form.Control
                                as="textarea"
                                style={{ height: 80, resize: "none" }}
                                placeholder="Enter one or more email addresses seperated by a comma"
                            />
                        </Form.Group>
                    </Card.Body>
                </Card>
                <Card style={{ padding: '10px' }} className="mb-3">
                    <Card.Body>
                        <Card.Title style={{ fontSize: 17 }}>Document</Card.Title>
                        <Form.Group className="mb-2">
                            <Form.Label>
                                Title
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Document title"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Message
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                style={{ height: 80 }}
                                placeholder="Enter message for all the recipients"
                            />
                        </Form.Group>
                    </Card.Body>
                </Card>
                <Card style={{ padding: '10px' }} className="mb-3">
                    <Card.Body>
                        <Card.Title style={{ fontSize: 17 }}>Document Settings</Card.Title>
                        <Form.Group>
                            <Form.Label>
                                Tags
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                style={{ height: 80 }}
                                placeholder="Enter one or more tags seperated by a comma"
                            />
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Container>
            <div className="text-end position-sticky bottom-0 bg-light shadow p-1">
                <button
                    className="p-1 px-4 border rounded-1 text-white bg-color fs-13 me-2"
                    onClick={() =>
                        handleAddNewTab("Pdf Builder", "fa-solid fa-file-lines", "pdfBuilderId")
                    }>
                    Next<i className="fa-solid fa-arrow-right-long text-white ms-2"></i>
                </button>
            </div>
        </div>
    );
};

export default AddNewDocument;

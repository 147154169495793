import React, { useState } from "react";
import { AddFolder } from "./Popups.js";
import { Collapse } from "react-bootstrap";
import {CiFolderOn } from "react-icons/ci";
import UseToggle from "../Hooks/UseToggle";
import { HiOutlineBookOpen } from "react-icons/hi2";
import { IoFolderOpenOutline } from "react-icons/io5";
import { VscChevronDown, VscChevronRight } from "react-icons/vsc";

const Sidebar = ({ handleAddNewTab, tabs }) => {
  const [mode, setMode] = useState("add");
  const { toggle, setToggle } = UseToggle();
  const [openCodeList, setOpenCodeList] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);

  return (
    <>
      <div
        className="overflow-auto border rounded-1"
        style={{ height: "calc(100vh - 80px)" }}
      >
        <div
          className={`border-bottom cursor-pointer p-1 ${tabs.some(
            (item) =>
              item.tabId === "dashBoardID" && item.status === "active"
          ) && "activeMenu"
            }`}
          onClick={() =>
            handleAddNewTab(
              "Dashboard",
              "fa-solid fa-book-open",
              "dashBoardID"
            )
          }
        >
          <div className="hstack justify-content-between">
            <span>
              <HiOutlineBookOpen />
              <span className="ms-2 fs-13">Dashboard</span>
            </span>
          </div>
        </div>

        <div
          className={`text-truncate ${openCodeList && "activeMenu"
            } border-bottom cursor-pointer p-1`}
          onClick={() => setOpenCodeList(!openCodeList)}
          aria-expanded={openCodeList}
        >
          <div className="hstack justify-content-between">
            <span>
              {openCodeList ? <IoFolderOpenOutline /> : <CiFolderOn />}
              <span className="ms-2 fs-13">Document</span>
            </span>
            <span>
              {openCodeList ? <VscChevronDown /> : <VscChevronRight />}
            </span>
          </div>
        </div>
        <Collapse in={openCodeList}>
          <div>
            <div
              className={`border-bottom cursor-pointer p-1 ps-3 ${tabs.some(
                (item) =>
                  item.tabId === "codeListID" && item.status === "active"
              ) && "activeMenu"
                }`}
              title="My Document"
              onClick={() =>
                handleAddNewTab(
                  "My Document",
                  "fa-solid fa-book-open",
                  "codeListID"
                )
              }
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">My Document</span>
                </span>
              </div>
            </div>
            <div
              className={`border-bottom cursor-pointer p-1 ps-3 `}
              title="Team Documents"
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">Team Documents</span>
                </span>
              </div>
            </div>
          </div>
        </Collapse>

        <div
          className={`border-bottom cursor-pointer p-1 ${tabs.some(
            (item) =>
              item.tabId === "templatesID" && item.status === "active"
          ) && "activeMenu"
            }`}
            title="Templates"
          onClick={() =>
            handleAddNewTab(
              "Templates",
              "fa-solid fa-book-open",
              "templatesID"
            )
          }
        >
          <div className="hstack justify-content-between">
            <span>
              <HiOutlineBookOpen />
              <span className="ms-2 fs-13">Templates</span>
            </span>
          </div>
        </div>

        <div
          className={`text-truncate ${openSettings && "activeMenu"
            } border-bottom cursor-pointer p-1`}
          onClick={() => setOpenSettings(!openSettings)}
          aria-expanded={openSettings}
        >
          <div className="hstack justify-content-between">
            <span>
              {openSettings ? <IoFolderOpenOutline /> : <CiFolderOn />}
              <span className="ms-2 fs-13">Settings</span>
            </span>
            <span>
              {openSettings ? <VscChevronDown /> : <VscChevronRight />}
            </span>
          </div>
        </div>
        <Collapse in={openSettings}>
          <div>
            <div
              className={`border-bottom cursor-pointer p-1 ps-3 ${tabs.some(
                (item) =>
                  item.tabId === "settingsID" && item.status === "active"
              ) && "activeMenu"
                }`}
              title="Settings 1"
              onClick={() =>
                handleAddNewTab(
                  "Settings",
                  "fa-solid fa-book-open",
                  "settingsID"
                )
              }
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">Settings 1</span>
                </span>
              </div>
            </div>
            <div
              className={`border-bottom cursor-pointer p-1 ps-3 `}
              title="Settings 2"
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">Settings 2</span>
                </span>
              </div>
            </div>
          </div>
        </Collapse>

      </div>
      <AddFolder
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={`${mode === "add" ? "Add New" : "Edit"} Folder`}
        Close={"Close"}
        Submit={`${mode === "add" ? "Add" : "Edit"} Folder`}
        mode={mode}
        setMode={setMode}
      />
    </>
  );
};

export default Sidebar;

import React, { useState } from "react";
import Moment from "react-moment";
import { Table } from "react-bootstrap";
import Pagination from "../../../Hooks/Pagination";

const CodeListTableData = ({ CodeList }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = CodeList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(CodeList?.length / itemsPerPage);

  return (
    <>
      <Table className="text-nowrap mb-1" hover bordered>
        <thead className="position-sticky z-1" style={{ top: -1 }}>
          <tr>
            <th style={{ width: 40 }}>Sr.</th>
            <th>Title</th>
            <th>Status</th>
            <th>Last Activity</th>
            <th>Add Date | Time</th>
            <th>Modified Date | Time</th>
            <th style={{ width: 60 }}>
              <div className="text-center">Remove</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {result.map((item, index) => (
            <tr key={index} className="position-relative CP">
              <td>{(pageNumber - 1) * itemsPerPage + index + 1}</td>
              <td>{item.codeListId}</td>
              <td>{item.codeListName}</td>
              <td>{item.nciClodeListCode}</td>
              <td>
                <Moment format="DD MMM YYYY | HH:mm:ss">
                  {item.createdAt}
                </Moment>
              </td>
              <td>
                <Moment format="DD MMM YYYY | HH:mm:ss">
                  {item.updatedAt}
                </Moment>
              </td>
              <td>
                <div className="text-center">
                  {item.type === "post" ? (
                    <i
                      title="Delete Protocol"
                      className={`fa-solid fa-trash-can CP red`}
                    ></i>
                  ) : (
                    <i
                      title="No Delete Pre-Define Prorocol"
                      className={`fa-solid fa-trash-can gray`}
                    ></i>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={CodeList?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default CodeListTableData;

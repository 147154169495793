import React from "react";
import Moment from "react-moment";
import { Table } from "react-bootstrap";

const CodeListPropertiesTableData = () => {
  // const [pageNumber, setPageNumber] = useState(1);
  // const [itemsPerPage, setItemsPerPage] = useState(10);
  // const startIndex = (pageNumber - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const result = tableData?.slice(startIndex, endIndex);
  // const totalPage = Math.ceil(tableData?.length / itemsPerPage);
  return (
    <>
      <Table className="text-nowrap mb-1" hover bordered>
        <thead className="position-sticky z-1" style={{ top: -1 }}>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {/* {result.map((item, index) => ( */}
          <tr className="position-relative CP">
            <td>DocumentId</td>
            <td>56a38c39-38a9-4dc4-b09c-f9558d5a9780</td>
          </tr>
          <tr className="position-relative CP">
            <td>Tags</td>
            <td>56a38c39-38a9-</td>
          </tr>
          <tr className="position-relative CP">
            <td>Status</td>
            <td>Completed</td>
          </tr>
          <tr className="position-relative CP">
            <td>Sent On</td>
            <td>
              <Moment format="DD MMM YYYY | HH:mm:ss">
                12/07/2024
              </Moment>
            </td>
          </tr>
          <tr className="position-relative CP">
            <td>Last Activity On</td>
            <td>
              <Moment format="DD MMM YYYY | HH:mm:ss">
                24/07/2024
              </Moment>
            </td>
          </tr>
          <tr className="position-relative CP">
            <td>Completed On</td>
            <td>
              <Moment format="DD MMM YYYY | HH:mm:ss">
                24/07/2024
              </Moment>
            </td>
          </tr>
          <tr className="position-relative CP">
            <td>Download Option</td>
            <td>
              Combine
            </td>
          </tr>
          <tr className="position-relative CP">
            <td>Files</td>
            <td>Result.pdf</td>
          </tr>
          {/* ))} */}
        </tbody>
      </Table>
      {/* <div className="position-sticky bottom-0">
      <Pagination
        totalPage={totalPage}
        pageNumber={pageNumber}
        itemsPerPage={itemsPerPage}
        totalItems={tableData?.length}
        setPageNumber={setPageNumber}
        setItemsPerPage={setItemsPerPage}
      />
      </div> */}
    </>
  );
};

export default CodeListPropertiesTableData;

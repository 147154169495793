import React, { useState } from 'react'
import { Col, Form, Offcanvas, Row } from 'react-bootstrap'

const RadioChecksOffcanvas = ({ Show, Hide, Title, textProperties, onTextPropertyChange, radioChecks, checkBox }) => {

    const [radioButtons, setRadioButtons] = useState([{ id: 0 }]);

    const handleSizeChange = (e) => {
        const newSize = parseInt(e.target.value, 10);
        onTextPropertyChange('checkboxSize', newSize);
    };

    const handleAddRadioButton = (e) => {
        e.preventDefault();
        setRadioButtons([...radioButtons, { id: radioButtons.length }]);
    };

    const handleDeleteRadioButton = (id) => {
        setRadioButtons(radioButtons.filter(button => button.id !== id));
    };

    const handleChange = (id, e) => {
        const newRadioButtons = radioButtons.map(button => {
            if (button.id === id) {
                return { ...button, value: e.target.value };
            }
            return button;
        });
        setRadioButtons(newRadioButtons);
    };

    return (
        <>
            <Offcanvas show={Show} onHide={Hide} placement="end" style={{ width: '500px' }}>
                <Offcanvas.Header>
                    <Offcanvas.Title>{Title} </Offcanvas.Title>
                    <div onClick={Hide} className="close_modal" title="Close">
                        X
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        {checkBox && (
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={4}></Form.Label>
                                <Col sm={8}>
                                    <Form.Check aria-label="option 1" label="checked by default" defaultChecked />
                                </Col>
                            </Form.Group>
                        )}
                        {radioChecks && (
                            <Form.Group as={Row} className="mb-3">
                                {radioButtons?.map((button, index) => (
                                    <div className='d-flex gap-2'>
                                        <Form.Label column sm={4}>Radio Values {index + 1}</Form.Label>
                                        <Col sm={7}>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter radio value"
                                                value={button.value}
                                                onChange={(e) => handleChange(button.id, e)}
                                            />
                                        </Col>
                                        <Col sm={1}>
                                            <div className="py-1">
                                                <i className="fa-regular fa-trash-can text-danger" title="Delete" onClick={() => handleDeleteRadioButton(button.id)}
                                                    style={{ cursor: 'pointer' }}></i>
                                            </div>
                                        </Col>
                                    </div>
                                ))}
                                <Col sm={4}>
                                </Col>
                                <Col sm={8}>
                                    <button className="p-2 border rounded-1 text-color bg-#e4e9ed00 w-100 mt-2" onClick={handleAddRadioButton}>
                                        <i className="fa-solid fa-plus me-1 fs-13"></i>Add Radio Button
                                    </button>
                                </Col>
                            </Form.Group>
                        )}

                        {checkBox && (
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={4}>Data Sync Tag</Form.Label>
                                <Col sm={8}>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter data sync tag"
                                        value={textProperties.dataSyncTag}
                                        onChange={(e) => onTextPropertyChange('dataSyncTag', e.target.value)}
                                    />
                                </Col>
                            </Form.Group>
                        )}

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={4}>Checkbox Size</Form.Label>
                            <Col sm={8}>
                                <Form.Range
                                    value={textProperties.checkboxSize}
                                    onChange={handleSizeChange}
                                />
                            </Col>
                        </Form.Group>

                        {/* <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={4}>Position</Form.Label>
                            <Col sm={4}>
                                <Form.Control
                                    type="number"
                                    placeholder="X"
                                    value={textProperties.position.x}
                                    onChange={(e) => onTextPropertyChange('position', { ...textProperties.position, x: parseFloat(e.target.value) })}
                                />
                            </Col>
                            <Col sm={4}>
                                <Form.Control
                                    type="number"
                                    placeholder="Y"
                                    value={textProperties.position.y}
                                    onChange={(e) => onTextPropertyChange('position', { ...textProperties.position, y: parseFloat(e.target.value) })}
                                />
                            </Col>
                        </Form.Group> */}
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default RadioChecksOffcanvas
